/* eslint-disable no-script-url */
import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";

import Chip from "@material-ui/core/Chip";

import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import StatsContext from "../../../context/StatsContext";
import palette from "../../../theme/palette";

const useStyles = makeStyles({
  root: {
    backgroundColor: palette.common.yellow,
    width: "100%",
    height: "100%",
    padding: "2.5rem",
    boxShadow: "none"
  },
  depositContext: {
    margin: "2rem 0"
  },
  button: {
    paddingTop: "9px",
    paddingBottom: "9px"
  },
  textField: {
    padding: "5px"
  },
  chip: {
    margin: "8px"
  },
  rowFlex: {
    flexDirection: "row"
  }
});

export default function OddsLiga(props) {
  const statsRange = React.useContext(StatsContext);
  const [eventsData, setEventsData] = useState([]);
  const [filterItems, updateFilterItems] = useState([]);
  const [displayCategory, setCategory] = useState();
  useEffect(() => {
    const loadProgrammet = async () => {
      const result = await axios.get(
        "https://api.norsktippingpartner.net/feed/sportleagues" +
          "?session_key=" +
          statsRange.sid
      );

      let results = result.data.sports;
      setEventsData(results);
      updateFilterItems(results);
      setCategory(results[0].sportid);
    };
    loadProgrammet();
  }, []);

  const classes = useStyles();
  const [sportsChecked, setSportsChecked] = React.useState([]);
  const [arrangChecked, setArrangChecked] = React.useState([]);

  const handleChange = name => event => {
    setArrangChecked({ ...arrangChecked, [name]: event.target.checked });
  };

  const handleChangeSports = name => event => {
    setSportsChecked({ ...sportsChecked, [name]: event.target.checked });
  };

  const filterCategories = (categories, setCategory) =>
    categories.map(cat => (
      <Chip
        key={cat.sportid}
        label={cat.sportname}
        className={classes.chip}
        color={displayCategory === cat.sportid ? "primary" : "secondary"}
        onClick={() => filterCategory(cat.sportid)}
      />
    ));

  const filterCategory = cat => {
    setCategory(cat);
    updateFilters(eventsData, cat);
  };

  const updateFilters = (items, cat) =>
    updateFilterItems(
      items.filter(item => cat === item.sportid || cat === "Alle")
    );

  function createGameURL() {
    //"&state=10;1;["+stateSportIds+"];["+stateArrangementIds+"]"

    let stateSportIds = [];
    let stateArrangementIds = [];

    for (let key in arrangChecked) {
      if (arrangChecked.hasOwnProperty(key)) {
        if (arrangChecked[key]) {
          stateArrangementIds.push("arrangement-" + key);
        }
      }
    }

    for (let key in sportsChecked) {
      if (sportsChecked.hasOwnProperty(key)) {
        if (sportsChecked[key]) {
          stateSportIds.push("sport-" + key);
        }
      }
    }

    let gameURL =
      "https://www.norsk-tipping.no/mobile-internet/#/sport/V2/nettpartner?view=events&exclusivefilter=all&filters=langoddsen," +
      stateSportIds.join(",") +
      "," +
      stateArrangementIds.join(",");

    props.updateLeaguesURL(gameURL);
  }
  function renderArrangemens() {
    if (!filterItems[0] || filterItems[0].leagues.length < 1) return;

    const filteredItems = filterItems[0].leagues.map(row => (
      <FormControlLabel
        key={row.arrangementid}
        control={
          <Checkbox
            checked={!!arrangChecked[row.arrangementid]}
            onChange={handleChange(row.arrangementid)}
            value={row.arrangementid}
          />
        }
        label={row.arrangementname}
      />
    ));
    createGameURL();
    return filteredItems;
  }

  function renderSports() {
    if (!filterItems[0] || filterItems[0].leagues.length < 1) return;

    const filteredItems = (
      <FormControlLabel
        key={filterItems[0].sportid}
        control={
          <Checkbox
            checked={!!sportsChecked[filterItems[0].sportid]}
            onChange={handleChangeSports(filterItems[0].sportid)}
            value={filterItems[0].sportid}
          />
        }
        label={filterItems[0].sportname}
      />
    );

    createGameURL();
    return filteredItems;
  }

  return (
    <>
      <Grid item xs={12} align="center">
        {filterCategories(eventsData, filterCategory)}
      </Grid>
      <Grid item xs={12}>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormGroup className={classes.rowFlex}>
            {renderSports()}
            {renderArrangemens()}
          </FormGroup>
        </FormControl>
      </Grid>
    </>
  );
}
