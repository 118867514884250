import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";

import dummydata from "./data";
import update from "immutability-helper";

import TippetipsListHolder from "./components/TippetipsListHolder";
import StatsContext from "../../context/StatsContext";
import Snackbar from "@material-ui/core/Snackbar";
import MySnackbarContentWrapper from "../../components/MySnackbarContentWrapper";

import { withRouter } from "react-router-dom";
import RenameCollectionDialog from "./components/RenameCollectionDialog";

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  circleProg: {
    marginTop: "48px",
  },
}));

function TippetipsPage(props) {
  const classes = useStyles();

  const [tippetipsData, setTippetipsData] = useState(null);
  const [placementData, setPlacementData] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [snackMsg, setSnackMsg] = React.useState("Oppdatert!");
  const [snackVar, setSnackVar] = React.useState("success");
  const [deleteURL, setDeleteURL] = useState("");
  const [renameDialogOpen, setRenameDialogOpen] = useState(false);

  const statsRange = React.useContext(StatsContext);
  function handleClose(event) {
    setOpen(false);
  }

  function showAlert(msg, msgtype) {
    setSnackVar(msgtype);
    setSnackMsg(msg);
    setOpen(true);
  }

  React.useEffect(() => {
    const deleteData = async () => {
      const result = await axios.delete(deleteURL);
    };
    if (deleteURL != "") {
      deleteData();
    }
  }, [deleteURL]);

  useEffect(() => {
    const loadPlacements = async () => {
      const result = await axios.get(
        "https://api.norsktippingpartner.net/tippetips/feed?session_key=" +
          statsRange.sid
      );

      let results = result.data.feed;
      console.log(results);
      setTippetipsData(results);
    };

    loadPlacements();
  }, []);

  useEffect(() => {
    const loadPlacement = async () => {
      const result = await axios.get(
        "https://api.norsktippingpartner.net/tippetips?session_key=" +
          statsRange.sid
      );

      let results = result.data;
      console.log(results);
      setPlacementData(results);
    };

    loadPlacement();
  }, []);

  function doDelete(collectionId) {
    if (!collectionId) return;
    setDeleteURL(
      "https://api.norsktippingpartner.net/collection/" +
        collectionId +
        "?session_key=" +
        statsRange.sid
    );
    setSnackMsg("Tippset ble slettet!");

    setOpen(true);
  }
  /*
  function moveArr(old_index, new_index) {
    console.log("MOVE ARR");
    const updatedData = [...collectionData];
    if (new_index >= updatedData.length) {
      var k = new_index - updatedData.length + 1;
      while (k--) {
        updatedData.push(undefined);
      }
    }

    updatedData.splice(new_index, 0, updatedData.splice(old_index, 1)[0]);
    let newObj = [];
    for (var i = 0; i < updatedData.length; i++) {
      let position = i + 1;
      let tempObj = {};
      tempObj = updatedData[i];
      tempObj.position = position;
      newObj.push(tempObj);
    }

    setCollectionData(newObj);
  }
  */
  function getContentById(kupongid) {
    const contentData = { ...tippetipsData };

    var filterData = "";
    Object.keys(contentData).map(function (key) {
      if (
        parseInt(contentData[key].content_id, 10) === parseInt(kupongid, 10)
      ) {
        filterData = contentData[key];
        return key;
      }
      return key;
    });

    return filterData;
  }
  function saveTippetips(kupong1, kupong2) {
    let updatedData = { ...placementData };

    if (!updatedData.positions[0]) {
      let newData = {};
      let contentData = [];
      contentData.push(kupong1, kupong2);
      newData = {
        id: null,
        placement: "Tippetips-siden",
        clicks: "",
        displays: "",
        tag: "tippetips",
        width: 100,
        width_unit: "%",
        height: 800,
        height_unit: "px",
        content: contentData,
      };
      saveNewTippetipsToDB(newData);
    } else {
      console.log(kupong1, kupong2, updatedData);
      const k1 = getContentById(kupong1.contentid);
      const k2 = getContentById(kupong2.contentid);
      updatedData.positions[0].content[0] = { ...k1 };
      updatedData.positions[0].content[1] = { ...k2 };
      setPlacementData(updatedData);
      saveTippetipsToDB(updatedData.positions[0]);
    }
  }
  function saveNewTippetipsToDB(tippeObj) {
    if (!tippeObj) return;

    const doSaveTippetips = async () => {
      const result = await axios.post(
        "https://api.norsktippingpartner.net/position/" +
          "?session_key=" +
          statsRange.sid,
        { ...tippeObj }
      );

      let results = result.data;
      console.log(results);
      setSnackMsg("Lagret!");
      setSnackVar("success");
      setOpen(true);
    };
    doSaveTippetips();
  }

  function saveTippetipsToDB(tippeObj) {
    if (!tippeObj) return;

    const doSaveTippetips = async () => {
      const result = await axios.put(
        "https://api.norsktippingpartner.net/position/" +
          tippeObj.id +
          "?session_key=" +
          statsRange.sid,
        { ...tippeObj }
      );

      let results = result.data;
      console.log(results);
      setSnackMsg("Lagret!");
      setSnackVar("success");
      setOpen(true);
    };
    doSaveTippetips();
  }
  function handleOpenRenameDialog() {
    setRenameDialogOpen(!renameDialogOpen);
    console.log("open");
  }

  function handleChange() {
    console.log("handleChange");
  }

  function renameCollection() {
    console.log("renameCollection");
  }

  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      {placementData && (
        <TippetipsListHolder
          items={tippetipsData}
          setTippetipsData={setTippetipsData}
          placementData={placementData}
          saveTippetips={saveTippetips}
          showAlert={showAlert}
          doDelete={doDelete}
          setSnackMsg={setSnackMsg}
          setSnackMode={setSnackVar}
          setOpen={setOpen}
        />
      )}
      {renameDialogOpen && (
        <RenameCollectionDialog
          open={true}
          handleClose={handleOpenRenameDialog}
          handleChange={handleChange}
          renameCollection={renameCollection}
        />
      )}

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <MySnackbarContentWrapper
          onClose={handleClose}
          variant={snackVar}
          message={snackMsg}
        />
      </Snackbar>
    </main>
  );
}

export default withRouter(TippetipsPage);
