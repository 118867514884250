import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { Element, animateScroll as scroll, scroller } from "react-scroll";

import StatsContext from "../../../context/StatsContext";
import axios from "axios";
import { marketNamesArr } from "../../../common/consts";

import ElementItemOdds from "./ElementItemOdds";

import FilterListIcon from "@material-ui/icons/FilterList";
import OddsChooser from "./OddsChooser";
import moment from "moment";

import DeleteIcon from "@material-ui/icons/Delete";

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function translateMarketName(val){
  if(marketNamesArr[val] ){
    return marketNamesArr[val];
  }
  return val;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Start",
  },
  { id: "Match", numeric: false, disablePadding: false, label: "Match" },
  { id: "Sport", numeric: false, disablePadding: false, label: "Sport" },
  { id: "Type", numeric: false, disablePadding: false, label: "Type" },
  { id: "Utfall", numeric: false, disablePadding: false, label: "Utfall" },
  { id: "arrow", numeric: false, disablePadding: false, label: "" },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <Tooltip title="Filter list" align="right">
        <IconButton aria-label="filter list">
          <FilterListIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: "auto",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  betsCell: {
    maxWidth: "400px",
    minWidth: "240px",
    paddingRight: "0px",
  },
  arrowEx: {
    width: "15px",
    cursor: "pointer",
  },
  hideRow: {
    display: "none",
  },
  subCells: {
    backgroundColor: "rgba(0, 0, 0, 0.07)",
  },
}));

export default function OddsProgramChosen(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("dummy");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [showMatches, setShowMatches] = React.useState([]);
  const [saveNew, setSaveNew] = React.useState(null);
  const [updateRow, setUpdateRow] = useState(false);

  const statsRange = React.useContext(StatsContext);

  const [values, setValues] = React.useState({
    feedback: "",
    tema: "generelt",
  });

  const arrBets = [];

  useEffect(() => {
    if (!saveNew) return;

    if (saveNew.tipsingress.length < 1 && saveNew.tipstext.length < 1) {
      props.setSnackMsg("Vennligst fyll ut tipset ditt!");
      props.setSnackMode("error");
      props.setOpen(true);
      setSaveNew(false);
      return;
    }
    const saveNewTip = async () => {
      setSaveNew(false);
      const result = await axios.post(
        "https://api.norsktippingpartner.net/oddstips/" +
          "?session_key=" +
          statsRange.sid,
        {
          ...saveNew,
        }
      );

      let results = result.data;

      props.setSnackMsg("Tippset ble lagret!");
      props.setSnackMode("success");
      props.finishEditMode();

      props.updatedSavedItemId(
        results.id,
        saveNew.speschoice,
        saveNew.tipstext,
        saveNew.tipsingress,
        saveNew.priority,
        saveNew.publish
      );
      props.setOpen(true);

    };
    saveNewTip();
  }, [props, saveNew, statsRange.sid]);

  function saveTips(
    eventId,
    marketId,
    marketName,
    selectionId,
    speschoice,
    tipsingress,
    tipstext,
    priority,
    publish,
    id,
    index,
    bettime
  ) {
    const saveObj = {};
    saveObj.eventId = eventId;
    saveObj.marketId = marketId;
    saveObj.marketName = marketName;
    saveObj.selectionId = selectionId;
    saveObj.speschoice = speschoice;
    saveObj.tipsingress = tipsingress;
    saveObj.tipstext = tipstext;
    saveObj.priority = priority;
    saveObj.publish = publish;
    saveObj.displayorder = index + 1;
    saveObj.time = bettime;


    saveObj.id = id;
    if (id) {
      setUpdateRow(saveObj);
    } else {
      setSaveNew(saveObj);
    }
  }

  useEffect(() => {
    const updateTips = async (id) => {
      const result = await axios.put(
        "https://api.norsktippingpartner.net/oddstips/" +
          id +
          "?session_key=" +
          statsRange.sid,
        {
          ...updateRow,
        }
      );

      let results = result.data;
    };
    if (updateRow) {
      updateTips(updateRow.id);
      console.log(updateRow);
      props.updatedSavedItem(
        updateRow.id,
        updateRow.speschoice,
        updateRow.tipstext,
        updateRow.tipsingress,
        updateRow.priority,
        updateRow.publish
      );

      props.setSnackMsg("Tippset ble lagret!");
      props.setSnackMode("success");
      props.finishEditMode();
      props.setOpen(true);

      setUpdateRow(false);
    }
  }, [updateRow, statsRange.sid, props]);

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = props.selectedData.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function removeDot(item) {
    console.log("item",item);
    return item.split(".").join("");
  }
  function scrolltoelem(marketId) {
    scroller.scrollTo("element" + marketId, {
      duration: 400,
      delay: 0,
      offset: -100,
      smooth: "easeInOutQuart",
    });
  }

  function updateOddsString() {
    let gameString =
      "https://www.norsk-tipping.no/sales/validateCouponAndStoreToSession.json?0.gameID=12&0.selections=";
    let selectionString = "";
    let rowPriceString = "&0.rowPrice=100&0.systemS&0.odds=";
    let rowPrice = "";

    //.gameID=12&0.selections=4097601,2,375;4097598,1,137&0.rowPrice=100&0.systemS&0.odds=3.75;&0.rowPrice=100&0.systemS&0.odds=1.37
    // arrBets.map((item) => {
    //   selectionString +=
    //     item.marketId +
    //     "," +
    //     item.selectionId +
    //     "," +
    //     removeDot(item.odds) +
    //     ";";
    //   rowPrice += rowPriceString + item.selectionOdds;
    // });
    // let finalGameString = gameString + selectionString + rowPrice;
    // if (arrBets.length < 1) {
    //   finalGameString = "";
    // }

    props.updateGameURL(gameString);
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;

  function renderSubTable(row, ind) {
    const TblRow = row.markets.map((bet, index) => {
      const labelId = `enhanced-table-checkbox-${index}`;
      const clsArr = [classes.betsCell];
      console.log("BET", bet);
      if (!bet.selectionId) {
        return;
      }

      if (index > 0 || showMatches === row.eventId) {
        clsArr.push(classes.subCells);
      }
      const betSel = [];

      for(var i=0; i< bet.selections.length; i++){
        betSel.push(bet.selections[i].selectionOdds);
      }
      arrBets.push({
        marketId: bet.marketId,
        eventId: row.eventId,
        selectionId: bet.selectionId,
        odds: betSel,
      });

      console.log(arrBets);

      const title = row.title ? row.title : "";

      return (
        <Element name={"element" + bet.marketId} key={bet.marketId}>
          <ElementItemOdds
            index={ind}
            type={row.type}
            matchtime={row.startTime}
            eventName={row.eventName}
            oddsname={translateMarketName(bet.marketName)}
            id={row.id}
            eventId={row.eventId}
            bet={bet}
            title={title}
            tipsingress={row.tipsingress}
            tipstext={row.tipstext}
            priority={row.priority}
            bettime={row.startTime}
            publish={row.publish}
            displayorder={row.displayorder}
            zeroMinWidth
            handleOpenDialog={handleClick}
            addToFavorites={handleClick}
            updateOdds={props.updateOdds}
            doDelete={props.doDelete}
            moveArr={props.moveArr}
            total={props.selectedData.length}
            saveTips={saveTips}
            editMode={props.editMode}
            editId={props.editId}
            setEditId={props.setEditId}
          />
        </Element>
      );
    });
    return TblRow;
  }

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, props.chosenEvents - page * rowsPerPage);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.tableWrapper}>
          {stableSort(props.selectedData, getSorting(order, orderBy)).map(
            (row, index) => {
              const Tbl = renderSubTable(row, index);
              return Tbl;
            }
          )}

          {updateOddsString()}
        </div>
      </div>
    </>
  );
}
