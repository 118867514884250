import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import StatsContext from "../../context/StatsContext";
import axios from "axios";

import clsx from "clsx";
import palette from "../../theme/palette";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FeedbackOutlinedIcon from "@material-ui/icons/FeedbackOutlined";
import MenuItem from "@material-ui/core/MenuItem";
import Snackbar from "@material-ui/core/Snackbar";
import MySnackbarContentWrapper from "../../components/MySnackbarContentWrapper";

import Paper from "@material-ui/core/Paper";

import { withRouter } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto"
  },
  mainheading: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(2),
    alignItems: "center",
    display: "flex"
  },
  heading: {
    marginBottom: theme.spacing(3)
  },

  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0",
      paddingRight: "0",
      paddingTop: theme.spacing(0)
    }
  },
  iconRight: {
    marginRight: "1.2rem"
  },
  paper: {
    width: "100%",
    height: "100%",
    padding: "2.5rem"
  },
  greenPaper: {
    backgroundColor: palette.secondary.light,
    boxShadow: "none"
  },
  contactFormPaper: {
    maxWidth: "71.5rem"
  },
  bigAvatar: {
    marginRight: theme.spacing(3),
    width: 92,
    height: 92
  },
  flexDiv: {
    display: "flex",
    alignItems: "center"
  },
  flexCol: {
    display: "flex",
    flexDirection: "column"
  },
  button: {
    alignSelf: "flex-end",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  }
}));

function FeedbackPage(props) {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    feedback: "",
    tema: "generelt"
  });
  const [saveNew, setSaveNew] = React.useState(null);
  const [snackMsg, setSnackMsg] = React.useState("Oppdatert");
  const [snackMode, setSnackMode] = React.useState("success");
  const [open, setOpen] = React.useState(false);
  const statsRange = React.useContext(StatsContext);
  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  useEffect(() => {
    if (!saveNew) return;

    if (values.feedback.length < 1) {
      setSnackMsg("Du må skrive en tilbakemelding!");
      setSnackMode("error");
      setOpen(true);
      setSaveNew(false);

      return;
    }
    const sendFeedback = async () => {
      const result = await axios.post(
        "https://api.norsktippingpartner.net/feedback/" +
          "?session_key=" +
          statsRange.sid,
        {
          ...values
        }
      );

      let results = result.data;
      if (results.status === "OK") {
        setSnackMsg("Takk! Tilbakemeldingen din ble sendt!");
        setSnackMode("success");
        setValues({ ...values, feedback: "", tema: "generelt" });
        setOpen(true);
        setSaveNew(false);
      }
    };
    sendFeedback();
  }, [saveNew, statsRange.sid, values]);

  function saveFeedback() {
    setSaveNew(true);
  }

  function handleCloseSnack(event) {
    setOpen(false);
  }

  const temaOptions = [
    {
      value: "generelt",
      label: "Generelt"
    },
    {
      value: "forsiden",
      label: "Forsiden"
    },
    {
      value: "plassering",
      label: "Plassering"
    },
    {
      value: "innhold",
      label: "Innhold"
    },
    {
      value: "lenker",
      label: "Lenker"
    },
    {
      value: "tidsstyring",
      label: "Tidsstyring"
    }
  ];

  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />

      <Container maxWidth="xl" className={classes.container}>
        <Grid container spacing={0}>
          <Grid container spacing={3} className={classes.centerVert}>
            <Grid item xs={12} align="left">
              <Typography
                variant="h3"
                component="h3"
                align="left"
                className={classes.mainheading}
              >
                <FeedbackOutlinedIcon className={classes.iconRight} /> Gi
                tilbakemelding
              </Typography>
            </Grid>
            <Grid item xs={12} align="left">
              <Paper
                className={clsx(
                  classes.greenPaper,
                  classes.paper,
                  classes.contactFormPaper
                )}
              >
                <Typography
                  color="textPrimary"
                  className={classes.depositContext}
                >
                  Hvordan synes du den nye partnerportalen fungerer? Fant du en
                  feil? Vi vil gjerne høre fra deg!
                </Typography>
              </Paper>
            </Grid>

            <Grid item xs={12} align="left">
              <Paper
                className={clsx(
                  classes.paper,
                  classes.contactFormPaper,
                  classes.flexCol
                )}
              >
                <Grid container>
                  <Grid item xs={12} sm={4} align="left">
                    <TextField
                      id="filled-select-tema"
                      select
                      label="Velg tema"
                      className={classes.textField}
                      value={values.tema}
                      onChange={handleChange("tema")}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu
                        }
                      }}
                      margin="normal"
                      variant="filled"
                    >
                      {temaOptions.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={8} align="left"></Grid>
                  <Grid item xs={12} align="left">
                    <TextField
                      id="filled-feedback"
                      label="Din tilbakemelding"
                      className={classes.textField}
                      value={values.feedback}
                      onChange={handleChange("feedback")}
                      margin="normal"
                      variant="filled"
                      multiline
                      rows="6"
                      rowsMax="6"
                    />
                    <Button
                      variant="contained"
                      className={classes.button}
                      onClick={saveFeedback}
                    >
                      Send inn
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        open={open}
        autoHideDuration={2000}
        onClose={handleCloseSnack}
      >
        <MySnackbarContentWrapper
          onClose={handleCloseSnack}
          variant={snackMode}
          message={snackMsg}
        />
      </Snackbar>
    </main>
  );
}

export default withRouter(FeedbackPage);
