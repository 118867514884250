import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { capitalize } from "../../../common/consts";
import FormSelectMobile from "../../../components/FormSelectMobile";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import palette from "../../../theme/palette";
import CircularProgress from "@material-ui/core/CircularProgress";

import TableContent from "./TableContent";
import MenuSortContent from "../../../components/MenuSortContent";
import EmptyContent from "./EmptyContent";
import StatsContext from "../../../context/StatsContext";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  clholder: {
    display: "flex",
    overflow: "auto",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  filterlist: {
    margin: "32px 0",
  },
  chip: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  bigIcon: {
    verticalAlign: "middle",
    width: 24,
    height: 24,
    color: palette.text.secondary,
  },
  buttonMenuLeft: {
    marginRight: "10px",
  },
  circleProg: {
    marginTop: "24px",
  },
}));

function ContentList(props) {
  const isModal = props.isModal;
  const isMobile = useMediaQuery("(max-width:767px)");

  const chosenContent = props.chosencontent ? props.chosencontent : "Alle";

  const [displayCategory, setCategory] = useState(chosenContent);
  const [sort, setSort] = useState(null);
  const [filterRows, setFilterRows] = useState(null);
  const [filterItems, setFilterItems] = useState(null);

  const [data, setData] = useState([]);

  const calledOnce = useRef(false);
  const calledOnce2 = useRef(false);

  const statsRange = React.useContext(StatsContext);
  const url =
    props.type === "nytt"
      ? "https://api.norsktippingpartner.net/content/templates?session_key=" +
        statsRange.sid
      : "https://api.norsktippingpartner.net/content?session_key=" + statsRange.sid;
  useEffect(() => {
    if (calledOnce.current) {
      return;
    }
    const fetchData = async () => {
      const result = await axios(url);

      if (props.type === "nytt") {
        setData(result.data.contentTypes);
        parseFilter(result.data.contentTypes);
        parseFilterRows(result.data.contentTypes);
      } else {
        setData(result.data.content);
        parseFilter(result.data.content);
        parseFilterRows(result.data.content);
      }
    };
    fetchData();
    calledOnce.current = true;
  }, [parseFilterRows, props.type, url]);

  useEffect(() => {
    if (calledOnce2.current) {
      return;
    }
    setCategory(chosenContent);
    parseFilterRows(data);
    calledOnce2.current = true;
    // Update the document title using the browser API
  }, [chosenContent, data, parseFilterRows]);

  const classes = useStyles();

  function parseFilter(rows) {
    if (!rows) return;

    let i = 0;

    var occurences = rows.reduce(function (r, row) {
      r[row.group] = ++r[row.group] || 1;
      return r;
    }, {});

    var result = Object.keys(occurences).map(function (key) {
      return { key: key, value: occurences[key] };
    });

    let filterItemsArr = [
      {
        id: 0,
        category: "Alle",
        nr: rows.length,
      },
    ];

    for (const item of result) {
      i = i + 1;

      filterItemsArr.push({
        id: i,
        category: item.key,
        nr: item.value,
      });
    }

    setFilterItems(filterItemsArr);
  }

  function parseFilterRows(rows) {
    if (!rows) return;
    setFilterRows(
      rows.filter(
        (item) =>
          (item.group &&
            chosenContent.toLowerCase() === item.group.toLowerCase()) ||
          chosenContent === "Alle"
      )
    );
  }

  const filterCategory = (cat) => {
    setCategory(cat);

    updateFilters(data, cat);
    cat = cat.toLowerCase();
    if (cat === "alle") {
      cat = "";
    }
    if (props.match.path === "/newplacement") {
      //chosencontent = cat;
    } else {
      if (isModal !== true) {
        props.history.push("/content/" + cat);
      }
    }
  };

  const updateFilters = (items, cat) => {
    setFilterRows(
      items.filter(
        (item) =>
          cat.toLowerCase() === item.group.toLowerCase() || cat === "Alle"
      )
    );
  };

  const filterCategories = (categories, setCategory) => {
    return categories.map((cat) => (
      <Chip
        key={cat.id}
        label={capitalize(cat.category) + " " + cat.nr}
        className={classes.chip}
        color={
          chosenContent.toLowerCase() == cat.category.toLowerCase()
            ? "primary"
            : "secondary"
        }
        onClick={() => filterCategory(cat.category)}
      />
    ));
  };

  const filterCategoriesMobile = (categories, setCategory) => (
    <FormSelectMobile
      onChange={handleMobileFilterChange}
      items={categories}
      label={"Vis innhold"}
      selectedValue={chosenContent}
      isDisabled={false}
      isStart={true}
    />
  );

  const handleMobileFilterChange = (event) => {
    filterCategory(event.currentTarget.value);
    console.log(event.currentTarget.value);
  };

  function sortPlacementBy(itemKey, order) {
    const newOrder = filterRows.sort(function (a, b) {
      var keyA = a[itemKey],
        keyB = b[itemKey];
      // Compare the 2 dates
      if (keyA < keyB) return -1 * order;
      if (keyA > keyB) return 1 * order;
      return 0;
    });

    setFilterRows(newOrder);
    setSort(itemKey);
  }

  function sortPlacementByContent(order) {
    if (!order) order = 1;
    const newOrder = filterRows.sort(function (a, b) {
      var keyA = a.name,
        keyB = b.name;
      // Compare the 2 dates
      if (keyA < keyB) return -1 * order;
      if (keyA > keyB) return 1 * order;
      return 0;
    });

    setFilterRows(newOrder);
    setSort("content");
  }
  function sortPlacementByStats(itemKey) {
    const order = 1;
    const newOrder = filterRows.sort(function (a, b) {
      var keyA = a.stats[24][itemKey],
        keyB = b.stats[24][itemKey];
      // Compare the 2 dates
      if (keyA < keyB) return -1 * order;
      if (keyA > keyB) return 1 * order;
      return 0;
    });

    setFilterRows(newOrder);
    setSort(itemKey);
  }

  return (
    <React.Fragment>
      {!filterItems && (
        <Grid item xs={12} className={classes.circleProg} align="center">
          <CircularProgress disableShrink />
        </Grid>
      )}
      {filterRows && filterRows.length > 0 && (
        <Grid
          item
          xs={10}
          sm={11}
          align="center"
          className={classes.filterlist}
        >
          {!isMobile && filterCategories(filterItems, filterCategory)}
          {isMobile && filterCategoriesMobile(filterItems, filterCategory)}
        </Grid>
      )}
      <Grid item xs={2} sm={1} container direction="row" justify="flex-end">
        {props.type === "egen" && filterRows && filterRows.length > 0 && (
          <MenuSortContent
            sortPlassering={sortPlacementBy}
            sortContent={sortPlacementByContent}
            sortStats={sortPlacementByStats}
          />
        )}
      </Grid>
      {filterItems && filterRows && filterRows.length < 1 && <EmptyContent />}
      {filterRows && filterRows.length > 0 && (
        <Grid className={classes.clholder} container spacing={0}>
          <TableContent
            selectedCat={chosenContent}
            rows={filterRows}
            doOpenModal={props.clickToOpenModal}
            type={props.type}
            isModal={isModal}
            updateContent={props.updateContent}
          />
        </Grid>
      )}
    </React.Fragment>
  );
}

export default withRouter(ContentList);
