/* eslint-disable no-script-url */
import React, { useState, useEffect } from "react";
import clsx from "clsx";

import Typography from "@material-ui/core/Typography";
import axios from "axios";
import StatsContext from "../../../context/StatsContext";
import update, { extend } from "immutability-helper";
import { makeStyles } from "@material-ui/core/styles";
import TrendingUp from "@material-ui/icons/TrendingUp";
import palette from "../../../theme/palette";
import SettingsIcon from "@material-ui/icons/SettingsOutlined";
import Visibility from "@material-ui/icons/Visibility";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Element, animateScroll as scroll, scroller } from "react-scroll";
import AlertDialog from "../../../components/AlertDialog";
import Snackbar from "@material-ui/core/Snackbar";
import MySnackbarContentWrapper from "../../../components/MySnackbarContentWrapper";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";

import Grid from "@material-ui/core/Grid";
import OddsProgramTable from "./OddsProgramTable";
import OddsProgramChosen from "./OddsProgramChosen";
import OddsSettingDialog from "./OddsSettingDialog";
import OddsCodeDialog from "./OddsCodeDialog";

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
  },
  circleProg: {
    marginTop: "48px",
  },
  elemPad: {
    marginTop: "48px",
  },
  heading: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  greyIcons: {
    color: palette.text.secondary,
  },
  iconStyles: {
    verticalAlign: "middle",
    marginRight: theme.spacing(3),
    width: 24,
    height: 24,
  },
  menu: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    cursor: "pointer",
  },
  btnRightPad: {
    marginRight: "12px",
  },
}));
export default function OddsProgrammet(props) {
  const classes = useStyles();
  const [eventsData, setEventsData] = useState([]);
  const [chosenEvents, setChosenEvents] = useState(0);
  const [chosenBets, setChosenBets] = useState();
  const [selectedData, setSelectedData] = React.useState([]);
  const [savedTips, setSavedTips] = React.useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editId, setEditId] = useState(false);
  const [open, setOpenDialog] = useState(false);

  const [openSnack, setOpen] = React.useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("Er du sikker på at du vil slette?");
  const [deleteURL, setDeleteURL] = useState("");
  const [delItem, setDelItem] = useState(false);
  const [snackMsg, setSnackMsg] = React.useState("Oppdatert");
  const [snackMode, setSnackMode] = React.useState("success");
  const [values, setValues] = useState([]);
  const statsRange = React.useContext(StatsContext);

  const [openCode, setOpenCode] = useState(false);

  /*
  {"arg":"{\"mid\":1243924,\"bid\":4365875,\"tipc\":\"1\",\"tipi\":\"Ingess\",\"tipt\":\"Text\",\"spes\":\"Tips\",\"pp\":1}"}
  */

  const statsPartnerId = statsRange.partnerId;
  const statsPartnerCode = statsRange.partnerCode;
  function confirmedDelete(eventId, marketId, id) {
    //const selectedMatch = eventsData.filter((item, => item.matchid === matchid);

    let updatedMatchKey;
    let nrOfSelectedBets = chosenEvents;
    const selectedMatch = eventsData.filter(function (item, key) {
      if (item.eventId === eventId) {
        updatedMatchKey = key;
        return item;
      }
      return null;
    });

    let updatedBetKey;
    const selectedBet = selectedMatch[0].markets.filter(function (
      item,
      key
    ) {
      if (item.marketId === marketId) {
        updatedBetKey = key;
        return item;
      }
    });

    let selectedOddsId = null;

    nrOfSelectedBets = nrOfSelectedBets - 1;

    const updatedItem = update(eventsData, {
      [updatedMatchKey]: {
        markets: { [updatedBetKey]: { selected: { $set: selectedOddsId } } },
      },
    });
    setChosenEvents(nrOfSelectedBets);
    setEventsData(updatedItem);
    deleteOdds(eventId, marketId);
    handleOpenAlert();
    doDelete(id);
  }

  function setCopy() {
    setSnackMsg("Lenken er kopiert!");
    setOpen(true);
  }

  function getPlacementURL() {
    return `<script type='text/javascript' src='//widget.tippebannere.no/v3/Scripts/nt-utilities.js'></script><iframe title="ntWidget" id="NTOddsenSupermodul" frameBorder="0" scrolling="no" src="//oddstips.norsktippingpartner.no/v3/iframes/NTOddsen/index20.aspx?p_id=${statsPartnerId}&WT.mc_id=${statsPartnerCode}_oddstipsmodul" style="width:100%;height:800px"></iframe>`;
  }

  function getURL() {
    return `https://widget.tippebannere.no/v3/iframes/NTOddsen/index20.aspx?p_id=${statsPartnerId}&WT.mc_id=${statsPartnerCode}_oddstipsmodul`;
  }

  function doDelete(itemId) {
    if (!itemId) return;
    setDeleteURL(
      "https://api.norsktippingpartner.net/oddstips/" +
        itemId +
        "?session_key=" +
        statsRange.sid
    );
    setSnackMsg("Tippset ble slettet!");
    setSnackMode("success");
    setOpen(true);
  }

  function handleDelete(eventId, marketId, selectedbet, id) {
    const delObj = {};
    delObj.eventId = eventId;
    delObj.marketId = marketId;
    delObj.selectedbet = selectedbet;
    delObj.id = id;
    setDelItem(delObj);
    if (id) {
      setAlertMsg("Er du sikker på at du vil slette tipset?");
    } else {
      setAlertMsg("Er du sikker på at du vil forkaste tipset?");
    }
    handleOpenAlert();
  }

  function confirmDelete() {
    confirmedDelete(delItem.eventId, delItem.marketId, delItem.id);

    handleOpenAlert();
  }

  React.useEffect(() => {
    const deleteData = async () => {
      const result = await axios.delete(deleteURL);
    };
    if (deleteURL != "") {
      deleteData();
    }
  }, [deleteURL]);

  function saveItemOrder(itemArr) {
    if (!itemArr) return;

    if (itemArr.length < 1) {
      return;
    }
    const updateOrder = async () => {
      const result = await axios.post(
        "https://api.norsktippingpartner.net/oddstips/displayorder/" +
          "?session_key=" +
          statsRange.sid,
        [...itemArr]
      );

      let results = result.data;
      console.log(results);
    };
    updateOrder();
  }

  function saveSettingsToDB(settingObj) {
    if (!settingObj) return;

    const doSaveSettings = async () => {
      const result = await axios.post(
        "https://api.norsktippingpartner.net/oddstips/info/" +
          "?session_key=" +
          statsRange.sid,
        { ...settingObj }
      );

      let results = result.data;
      console.log(results);
      setSnackMsg("Lagret!");
      setSnackMode("success");
      setOpen(true);
    };
    doSaveSettings();
  }

  function handleOpenAlert() {
    setAlertOpen(!alertOpen);
  }
  function handleCloseSnack(event) {
    setOpen(false);
  }
  function moveArr(old_index, new_index) {
    const updatedData = [...selectedData];
    if (new_index >= updatedData.length) {
      var k = new_index - updatedData.length + 1;
      while (k--) {
        updatedData.push(undefined);
      }
    }
    updatedData.splice(new_index, 0, updatedData.splice(old_index, 1)[0]);

    setSelectedData(updatedData);
    renderArrPosition(updatedData);
  }
  function finishEditMode() {
    setEditMode(false);
  }

  function updatedSavedItemId(
    id,
    title,
    tipstext,
    tipsingress,
    priority,
    publish
  ) {
    const updatedMatchKey = selectedData.length - 1;

    const updatedItem = update(selectedData, {
      [updatedMatchKey]: {
        id: { $set: id },
        title: { $set: title },
        tipstext: { $set: tipstext },
        tipsingress: { $set: tipsingress },
        priority: { $set: priority },
        publish: { $set: publish },
      },
    });
    setSelectedData(updatedItem);
  }

  function updatedSavedItem(
    id,
    title,
    tipstext,
    tipsingress,
    priority,
    publish
  ) {
    let updatedMatchKey;

    const selectedMatch = selectedData.filter(function (item, key) {
      if (item.id === id) {
        updatedMatchKey = key;
        return item;
      }
      return null;
    });

    const updatedItem = update(selectedData, {
      [updatedMatchKey]: {
        id: { $set: id },
        title: { $set: title },
        tipstext: { $set: tipstext },
        tipsingress: { $set: tipsingress },
        priority: { $set: priority },
        publish: { $set: publish },
      },
    });
    setSelectedData(updatedItem);
  }

  function renderArrPosition(updatedItem) {
    const newObj = [];

    for (var i = 0; i < updatedItem.length; i++) {
      let displayorder = i + 1;
      let tempObj = {};
      tempObj.id = updatedItem[i].id;
      tempObj.displayorder = displayorder;
      newObj.push(tempObj);
    }
    saveItemOrder(newObj);
  }

  function deleteOdds(eventId, marketId) {
    console.log(eventId, marketId);
    const updatedData = selectedData.filter(
      (item) => item.markets[0] && item.markets[0].marketId != marketId
    );

    setSelectedData(updatedData);
    setEditMode(false);
  }
  function updateOdds(eventId, marketId, selectionId, id, editing) {
    console.log(eventId, marketId, selectionId, id, editing);

    const updatedData = selectedData.filter(
      (item) => item.markets[0] && item.markets[0].marketId === marketId
    );

    if (updatedData.length > 0) {
      setEditId(updatedData[0].id);
      if (editing === "null") {
        editing = false;
      }
    }

    scroller.scrollTo("elementtop", {
      duration: 400,
      delay: 0,
      offset: -100,
      smooth: "easeInOutQuart",
    });

    let editingMode = editing ? true : false;
    //const selectedMatch = eventsData.filter((item, => item.matchid === matchid);
    let updatedMatchKey, updatedMatchKeySelected;
    let nrOfSelectedBets = chosenEvents;
    const selectedMatch = eventsData.filter(function (item, key) {
      if (item.eventId === eventId) {
        updatedMatchKey = key;
        return item;
      }
      return null;
    });

    let selectedMatchChosen = [];
    selectedMatchChosen = selectedData.filter(function (item, key) {
      if (item.eventId === eventId) {
        item.markets.filter(function (bet, key2) {
          console.log(bet.marketId, marketId, bet.selectionId);
          if (bet.marketId === marketId && bet.selectionId) {
            updatedMatchKeySelected = key;
            return item;
          }
          return null;
        });
      }
      return null;
    });
    console.log("updatedMatchKeySelected", updatedMatchKeySelected);
    let updatedBetKey;
    const selectedBet = selectedMatch[0].markets.filter(function (
      item,
      key
    ) {
      if (item.marketId === marketId) {
        updatedBetKey = key;
        return item;
      }
    });

    let updatedOddsKey;

    const selectedOdds = selectedBet[0].selections.filter(function (item, key) {
      if (item.selectionId === selectionId) {
        updatedOddsKey = key;
        return item;
      }
    });

    let selectedOddsId = selectionId;

    if (selectedBet[0].selectionId === selectionId) {
      selectedOddsId = 4000;
      // nrOfSelectedBets = nrOfSelectedBets - 1;
    } else {
      nrOfSelectedBets = nrOfSelectedBets + 1;
    }

    const updatedItem = update(eventsData, {
      [updatedMatchKey]: {
        markets: { [updatedBetKey]: { selectionId: { $set: selectedOddsId } } },
      },
    });

    let updatedItemChosen, newItemChosen;

    console.log(selectedData, updatedBetKey);
    if (updatedMatchKeySelected >= 0) {
      updatedItemChosen = update(selectedData, {
        [updatedMatchKeySelected]: {
          markets: {
            [0]: { selectionId: { $set: selectedOddsId } },
          },
        },
      });
    } else {
      console.log("updatedBetKey", updatedBetKey);
      let arrLength = selectedMatch[0].markets.length;
      if (arrLength > 1) {
        newItemChosen = update(selectedMatch, {
          [0]: {
            markets: {
              [updatedBetKey]: { selectionId: { $set: selectedOddsId } },
              $splice: [
                [0, updatedBetKey],
                [updatedBetKey + 1, arrLength],
              ],
            },
            title: { $set: "" },
            tipsingress: { $set: "" },
            tipstext: { $set: "" },
            priority: { $set: 0 },
            publish: { $set: 0 },
          },
        });
      } else {
        newItemChosen = update(selectedMatch, {
          [0]: {
            markets: {
              [updatedBetKey]: { selectionId: { $set: selectedOddsId } },
            },
            title: { $set: "" },
            tipsingress: { $set: "" },
            tipstext: { $set: "" },
            priority: { $set: 0 },
            publish: { $set: 0 },
          },
        });
      }
    }

    setChosenEvents(nrOfSelectedBets);
    setEventsData(updatedItem);
    if (updatedItemChosen && updatedItemChosen.length > 0) {
      console.log("updatedItemChosen", updatedItemChosen);
      editingMode = false;
      setEditMode(editingMode);
      setSelectedData(updatedItemChosen);
    } else {
      console.log("newItemChosen", newItemChosen);
      console.log("selectedMatch", selectedMatch);
      setEditMode(editingMode);
      setSelectedData([...selectedData, ...newItemChosen]);
    }
  }

  useEffect(() => {
    const loadAllTips = async (alldata) => {
      const result = await axios.get(
        "https://api.norsktippingpartner.net/oddstips/" +
          "?session_key=" +
          statsRange.sid
      );

      let saveddata = result.data.tips;

      filterEventsData(alldata, saveddata);
    };

    const loadProgrammet = async () => {
      const result = await axios.get(
        "https://api.norsktippingpartner.net/feed/langoddsen" +
          "?session_key=" +
          statsRange.sid
      );

      let results = result.data.events;

      loadAllTips(results);
    };
    loadProgrammet();
  }, []);

  function filterEventsData(allData, savedData) {
    let loadedData = [];
    savedData.map((match, index) => {
      populateData(
        match.eventId,
        match.marketId,
        match.selectionId,
        match.speschoice,
        match.tipsingress,
        match.tipstext,
        match.priority,
        match.publish,
        match.displayorder,
        match.id,
        allData,
        loadedData
      );
    });
    setSelectedData([...selectedData, ...loadedData]);
    const selDataLength = loadedData.length;

    setChosenEvents(selDataLength);

    setEventsData(allData);
  }

  function populateData(
    eventId,
    marketId,
    selectionId,
    speschoice,
    tipsingress,
    tipstext,
    priority,
    publish,
    displayorder,
    id,
    allData,
    loadedItemsArray
  ) {
    if (!allData) return;
    for (let i = 0; i < allData.length; i++) {
      let updatedItem = {};


      if (allData[i].eventId == eventId) {
        updatedItem = JSON.parse(JSON.stringify(allData[i]));
        var k = allData[i].markets.length;
        while (k--) {
          if (allData[i].markets[k].marketId == marketId) {
            updatedItem.markets[k].selectionId = selectionId;
            allData[i].markets[k].selectionId = selectionId;
            updatedItem.title = speschoice;
            updatedItem.tipsingress = tipsingress;
            updatedItem.tipstext = tipstext;
            updatedItem.priority = priority;
            updatedItem.publish = publish;
            updatedItem.displayorder = displayorder;
            updatedItem.id = id;
          } else {
            updatedItem.markets.splice(k, 1);
          }
        }

        /*
        for (let k = 0; k < allData[i].betobjects.length; k++) {
          if (allData[i].betobjects[k].betid == betobjectid) {
            updatedItem.betobjects[k].selected = tipschoice;
            allData[i].betobjects[k].selected = tipschoice;
            updatedItem.title = speschoice;
            updatedItem.tipsingress = tipsingress;
            updatedItem.tipstext = tipstext;
            updatedItem.priority = priority;
            updatedItem.publish = publish;
            updatedItem.displayorder = displayorder;
            updatedItem.id = id;
          }
        }
        */
        loadedItemsArray.push(updatedItem);

        break;
      }
    }
  }

  function handleCodeCloseDialog() {
    setOpenCode(!openCode);
  }

  function handleCloseDialog() {
    setOpenDialog(!open);
  }

  function handleOpenDialog() {
    setOpenDialog(!open);
  }

  function stopPropag(event) {
    event.stopPropagation();
    return;
  }

  function saveSettings(settingsItem) {
    saveSettingsToDB(settingsItem);

    setOpenDialog(false);
  }

  function showPublishCode() {
    setOpenCode(true);
  }

  function showPublishCodeSingle() {
    //
  }

  return (
    <>
      <Grid item xs={8} align="left">
        <Element name="elementtop">
          <Typography
            variant="h1"
            component="h1"
            align="left"
            className={classes.heading}
          >
            Oddstips - Lag tips
          </Typography>
        </Element>
      </Grid>
      <Grid item xs={4} className={classes.menu}>
        <a
          href={getURL()}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.mrgRight}
          onClick={stopPropag}
        >
          <IconButton className={classes.button}>
            <Visibility className={classes.btnRightPad} />
          </IconButton>
        </a>

        <SettingsIcon onClick={handleOpenDialog} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3" component="h3" gutterBottom>
          <TrendingUp className={clsx(classes.iconStyles, classes.greyIcons)} />{" "}
          Valgte objekter
        </Typography>
        {!chosenEvents && (
          <Typography
            variant="body1"
            component="h3"
            align="center"
            gutterBottom
          >
            Velg objekter fra oddsprogrammet
          </Typography>
        )}
        <OddsProgramChosen
          selectedData={selectedData}
          savedData={savedTips}
          values={values}
          setValues={setValues}
          doDelete={handleDelete}
          updateOdds={updateOdds}
          chosenEvents={chosenEvents}
          setChosenBets={setChosenBets}
          updateGameURL={props.updateGameURL}
          moveArr={moveArr}
          editMode={editMode}
          finishEditMode={finishEditMode}
          updatedSavedItemId={updatedSavedItemId}
          updatedSavedItem={updatedSavedItem}
          setSnackMsg={setSnackMsg}
          setSnackMode={setSnackMode}
          setOpen={setOpen}
          editId={editId}
          setEditId={setEditId}
        />
      </Grid>

      <Grid item xs={12} className={classes.elemPad}>
        <Typography variant="h3" component="h3">
          <TrendingUp className={clsx(classes.iconStyles, classes.greyIcons)} />{" "}
          OddsProgrammet
        </Typography>

        {eventsData.length > 0 && (
          <OddsProgramTable
            eventsData={eventsData}
            setValues={setValues}
            updateOdds={updateOdds}
            editMode={editMode}
          />
        )}
      </Grid>
      {eventsData.length < 1 && (
        <Grid item xs={12} className={classes.circleProg} align="center">
          <CircularProgress disableShrink />
        </Grid>
      )}

      <Grid item xs={12} sm={12} align="right">
        <Button variant="contained" onClick={showPublishCode} color="primary">
          VIS PUBLISERINGSKODE
        </Button>
      </Grid>

      <OddsSettingDialog
        isOpen={open}
        saveSettings={saveSettings}
        handleClose={handleCloseDialog}
        values={props.values}
        setValues={props.setValues}
      />
      <OddsCodeDialog
        isOpen={openCode}
        handleClose={handleCodeCloseDialog}
        values={props.values}
        setValues={props.setValues}
        setCopy={setCopy}
        getPlacementURL={getPlacementURL}
      />
      <AlertDialog
        open={alertOpen}
        handleClose={handleOpenAlert}
        handleConfirm={confirmDelete}
        msg={alertMsg}
      />
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={openSnack}
        autoHideDuration={2000}
        onClose={handleCloseSnack}
      >
        <MySnackbarContentWrapper
          onClose={handleCloseSnack}
          variant={snackMode}
          message={snackMsg}
        />
      </Snackbar>
    </>
  );
}
