/* eslint-disable no-script-url */

import React, { useEffect } from "react";
import useAxios from "axios-hooks";

import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";

import Button from "@material-ui/core/Button";
import PasswordShowHide from "../../../components/PasswordShowHide";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { withRouter } from "react-router-dom";
import StatsContext from "../../../context/StatsContext";

import palette from "../../../theme/palette";
const useStyles = makeStyles(theme => ({
  /* seeMore: {
    marginTop: theme.spacing(3),
  },*/
  textField: {
    width: "100%"
  },
  formContainer: {
    padding: "0px 24px",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0)
    }
  },
  loginBtn: {
    width: "100%",
    marginTop: "4.5rem"
  },
  links: {
    marginTop: "4rem",
    display: "flex",
    justifyContent: "center"
  },
  boldLink: {
    fontWeight: 600,
    color: palette.primary.dark
  },
  errormsg: {
    textAlign: "center",
    color: palette.error.main
  }
}));

function LoginCard(props) {
  const classes = useStyles();
  const historyObject = props.history;
  const [values, setValues] = React.useState({
    reset_token: props.match.params.token,
    password: "",
    password2: ""
  });

  const [error, setError] = React.useState("");
  const statsRange = React.useContext(StatsContext);
  const [
    { data: loginData, loading: isLoading, error: isError },
    doLogin
  ] = useAxios(
    {
      url: "https://api.norsktippingpartner.net/user/setpassword",
      method: "POST"
    },
    { manual: true }
  );

  const handleSubmit = e => {
    if (values.password != values.password2) {
      setError("Ikke like passord");
    } else if (values.password.length < 6) {
      setError("Passord må minst ha 6 tegn");
    } else if (
      values.password === values.password2 &&
      values.password.length > 5
    ) {
      doLogin({
        data: { ...values }
      });
    } else {
      setError("Noe gikk galt");
    }
    //props.history.push('/dashboard');
  };

  useEffect(() => {
    if (!isLoading && loginData && loginData.status === "OK") {
      statsRange.updateSID(loginData.session_key);
      statsRange.updatePartner(loginData.partner_name);
      statsRange.updatePartnerCode(loginData.partner_code);
      statsRange.updateTime(loginData.stats_hours);

      historyObject.push("/dashboard");
    }
  }, [historyObject, isLoading, loginData, statsRange]);

  const handleChange = name => event => {
    setError("");
    setValues({ ...values, [name]: event.target.value });
  };

  return (
    <React.Fragment>
      <Typography variant="h2" component="h2" align="center" gutterBottom>
        Velkommen til Partnerportalen
      </Typography>
      <Typography variant="body2" align="center" gutterBottom>
        Vennligst skriv inn ditt passord
      </Typography>
      <ValidatorForm
        onSubmit={handleSubmit}
        onError={errors => console.log(errors)}
        className={classes.formContainer}
        autoComplete="off"
      >
        <PasswordShowHide
          password={values.password}
          onChange={handleChange("password")}
          title="Passord"
        />
        <PasswordShowHide
          password={values.password2}
          onChange={handleChange("password2")}
          title="Bekreft passord"
        />
        <Button variant="contained" className={classes.loginBtn} type="submit">
          LAGRE
        </Button>

        <div className={classes.links}>
          <Link
            component={RouterLink}
            variant="body2"
            className={classes.boldLink}
            to="/"
          >
            Logg inn
          </Link>
        </div>
      </ValidatorForm>
      <div className={classes.errormsg}>{error}</div>
    </React.Fragment>
  );
}
export default withRouter(LoginCard);
