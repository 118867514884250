import React, { useState, useEffect, useLayoutEffect, useRef } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import Typography from "@material-ui/core/Typography";

import update from "immutability-helper";
import useAxios from "axios-hooks";
import axios from "axios";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import StatsContext from "../../../context/StatsContext";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import PlacementProperties from "./PlacementProperties";

import TextField from "@material-ui/core/TextField";

import Snackbar from "@material-ui/core/Snackbar";
import MySnackbarContentWrapper from "../../../components/MySnackbarContentWrapper";
import AlertDialog from "../../../components/AlertDialog";

import FormTextItem from "../../../components/FormTextItem";

import FormCheckbox from "../../../components/FormCheckbox";
import TimeScheduleDialog from "../../../components/TimeScheduleDialog";
import PlacementContent from "./PlacementContent";
import Schedule from "@material-ui/icons/Schedule";
import palette from "../../../theme/palette";
import MenuItem from "@material-ui/core/MenuItem";
import { Element, animateScroll as scroll, scroller } from "react-scroll";

import { useScrollPosition } from "@n8tb1t/use-scroll-position";

import { Prompt } from "react-router-dom";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0),
      backgroundColor: "#fff",
    },
  },
  paper: {
    padding: theme.spacing(3),
    paddingTop: 0,
    display: "flex",
    overflow: "auto",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  button: {
    marginTop: theme.spacing(2),
  },
  fixedHeight: {
    height: 240,
  },
  iconStyles: {
    verticalAlign: "middle",
    marginRight: theme.spacing(1),
  },
  heading: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  modal: {
    position: "absolute",
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: "none",
  },
  gridFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  menuItems: {
    color: palette.text.primary,
    fontWeight: 600,
    marginRight: theme.spacing(2),
  },
  card: {
    margin: "10px 0",
    minWidth: 275,
    width: "100%",
    textAlign: "center",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    flexGrow: 1,
  },
  pos: {
    marginBottom: 12,
  },
  media: {
    height: 238,
  },
  bigIcon: {
    verticalAlign: "middle",
    width: 24,
    height: 24,
    color: palette.text.secondary,
  },
  hasCursor: {
    cursor: "pointer",
  },
  mrgBtm: {
    marginBottom: "20px",
  },
  addToScroll: {
    height: "400px",
  },
  contentHeading: {
    minHeight: "32px",
  },
  contentHeader: {
    margin: "0 -24px",
    width: "calc(100% + 48px)",
    padding: "24px",
    alignItems: "flex-start",
  },
  editModeBG: {
    backgroundColor: palette.common.blue,
  },
  btnMrgLft: {
    marginLeft: "4rem",
  },
  timeScheduleHeading: {
    margin: theme.spacing(4) + "px 0 0 " + theme.spacing(3) + "px",
  },
  greenHeading: {
    backgroundColor: palette.secondary.light,
  },
}));

function PlacementsEditItem(props) {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:767px)");

  const pId = props.match.params.id;
  let defaultMode = "view";

  let placItm = props.item;

  const targetRef = useRef();
  const [dimensions, setDimensions] = useState({});

  // holds the timer for setTimeout and clearInterval
  let movement_timer = null;

  // the number of ms the window size must stay the same size before the
  // dimension state variable is reset
  const RESET_TIMEOUT = 100;

  const test_dimensions = () => {
    // For some reason targetRef.current.getBoundingClientRect was not available
    // I found this worked for me, but unfortunately I can't find the
    // documentation to explain this experience
    if (targetRef.current) {
      setDimensions({
        width: targetRef.current.clientWidth - 50,
        height: targetRef.current.offsetHeight,
      });
    }
  };

  // This sets the dimensions on the first render
  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      clearInterval(movement_timer);
      movement_timer = setTimeout(test_dimensions, RESET_TIMEOUT);
    });
    test_dimensions();
  }, []);

  // every time the window is resized, the timer is cleared and set again
  // the net effect is the component will only reset after the window size
  // is at rest for the duration set in RESET_TIMEOUT.  This prevents rapid
  // redrawing of the component for more complex components such as charts

  /* REMOVE THIS FAVORITE HACK */
  if (props.item) {
  } else {
    let contentData = [
      {
        title: "",
        contenttype: "",
        contentitem: "",
        contentid: 999999,
        contentImg: "",
        url: "",
        widgetoptions: [],
      },
    ];

    if (props.itemcontent) {
      defaultMode = "edit";
      contentData = [
        {
          name: props.itemcontent.name,
          contenttype: props.itemcontent.contenttype,
          contentitem: "",
          contentid: props.itemcontent.id,
          contentImg: props.itemcontent.contentImg,
          url: props.itemcontent.url,
          widgetoptions: props.itemcontent.widgetoptions,
        },
      ];
    }

    placItm = {
      id: null,
      title: "",
      type: 1,
      placement: "",
      clicks: "",
      displays: "",
      tag: "",
      width: 480,
      width_unit: "px",
      height: 400,
      height_unit: "px",
      content: contentData,
    };
  }

  const [placementItem, updatePlacementItem] = useState(placItm);
  const [editingPlacementId, updateEditingPlacementId] = useState(pId);
  const [addNewPlacement, setAddNewPlacement] = useState(false);
  let [isBlocking, setIsBlocking] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [snackMsg, setSnackMsg] = React.useState("Lenken er kopiert!");

  const [timeScheduleOpen, setTimeScheduleOpen] = useState(false);
  const [hideHeader, setHideHeader] = React.useState(false);
  const [updateRow, setUpdateRow] = useState(false);
  const [alertOpen, setAlertOpen] = useState(null);
  const [alertOpenItem, setAlertOpenItem] = useState(null);

  const [deleteURL, setDeleteURL] = useState("");

  function handleOpenAlert(id) {
    setAlertOpen(id);
  }

  function handleOpenAlertItem(id) {
    setAlertOpenItem(id);
  }

  function updateBlockingStatus(status) {
    setIsBlocking(status);
  }

  let formdata = {};
  function generateURL(values) {
    let urlString = "";

    for (var k in values.formdata) {
      if (values.formdata.hasOwnProperty(k) && values.formdata[k] !== "") {
        urlString += k + "=" + values.formdata[k].value + "&";
      }
    }

    return (
      "https://widget.tippebannere.no/v3/" +
      placementItem.content[0].url +
      urlString
    );
  }

  function generateTrackingCode() {
    return `?WT.mc_id=${statsPartnerCode}_flaxbanner_produkt_${placementItem.placement}`;
  }

  function getPlacementURL() {
    return `<iframe
                    title="ntWidget"
                    id="contentFrame${placementItem.id}"
                    frameBorder="0"
                    scrolling="no"
                    src="https://api.norsktippingpartner.net/pos/${
                      placementItem.id
                    }"
                    style="
                      width: ${placementItem.width + placementItem.width_unit};
                      height: ${
                        placementItem.height + placementItem.height_unit
                      }
                  "></iframe>`;
  }

  function setEditingPlacementId(id) {
    updateEditingPlacementId(id);
  }
  function updatePlacementProps(name, val) {
    if (
      name !== "width_unit" &&
      name !== "height_unit" &&
      name !== "width" &&
      name !== "height"
    ) {
      return;
    }
    updatePlacementItem({ ...placementItem, [name]: val });
  }

  function updatePlacementPropsMulti(name, val, name2, val2) {
    updatePlacementItem({ ...placementItem, [name]: val, [name2]: val2 });
  }
  function parseFormdata(data) {
    if (!data) return;
    data.widgetoptions.forEach(function (item, key) {
      if (item.paramname) {
        let itemObj = {};
        itemObj.value = item.value;
        itemObj.name = item.name;
        itemObj.paramname = item.paramname;

        formdata[item.paramname] = itemObj;
        if (item.hasChildren) {
          parseFormdata(item.items);
        }
      }
    });
  }

  //DELETE
  function doDelete(itemId) {
    setDeleteURL(
      "https://api.norsktippingpartner.net/position/" +
        itemId +
        "?session_key=" +
        statsRange.sid
    );
  }

  React.useEffect(() => {
    const deleteItem = async () => {
      if (deleteURL != "") {
        try {
          const result = await axios.delete(deleteURL);

          gotoPlacementsListPage();
        } catch (e) {
          console.log(`😱 Axios request failed: ${e}`);
        }
      }
    };
    deleteItem();
  }, [deleteURL, gotoPlacementsListPage]);

  //parseFormdata(placementItem.content[0]);
  const [values, setValues] = React.useState({
    formdata: formdata,
    isDisabled: true,
    buttonsDisabled: true,
    mode: defaultMode,
  });

  function gotoPlacementsListPage() {
    props.history.push("/placements");
  }

  function handleCloseSnack(event) {
    setOpen(false);
  }

  const handleChangePlacementContent = (name) => (event) => {
    let newValue = event.target.value;

    const newState = update(placementItem, {
      content: { 0: { [name]: { $set: newValue } } },
    });

    updatePlacementItem(newState);
  };

  const handleChange = (name) => (event) => {
    let newValue = event.target.value;

    updatePlacementItem({ ...placementItem, [name]: newValue });
  };

  function handleChangeTag(value) {
    let newValue = value.toLowerCase();

    updatePlacementItem({ ...placementItem, tag: newValue });
  }

  function showHideHeader(val) {
    setHideHeader(val);
  }
  function handleOpenTimeschedule() {
    setTimeScheduleOpen(!timeScheduleOpen);
  }

  function scrollToElem(elem) {
    scroller.scrollTo(elem, {
      duration: 400,
      delay: 400,
      offset: -450,
      smooth: "easeInOutQuart",
    });
  }

  useEffect(() => {
    if (addNewPlacement) {
      const nrPlacements = placementItem.content.length - 1;

      scrollToElem("element-" + nrPlacements);
      setAddNewPlacement(false);
    }
  }, [addNewPlacement, placementItem.content.length]);

  function editTimeschedule(id) {
    setEditingPlacementId(id);
    setTimeScheduleOpen(!timeScheduleOpen);
  }

  function confirmDelete() {
    deletePlacement(alertOpen);
    handleOpenAlert(null);
  }

  function confirmDeleteItem() {
    doDelete(alertOpenItem);
    handleOpenAlertItem(null);
  }

  function handleCloseAlertItem() {
    setAlertOpenItem(false);
  }

  function handleCloseAlert() {
    setAlertOpen(false);
  }
  function handleDelete(id) {
    handleOpenAlert(id);
  }

  function handleDeleteWholePlacement(id) {
    handleOpenAlertItem(id);
  }

  function deletePlacement(id) {
    const newContentArray = placementItem.content.filter(function (
      content,
      index
    ) {
      return id !== index;
    });
    const newState = update(placementItem, {
      content: { $set: newContentArray },
    });
    updatePlacementItem(newState);

    scroll.scrollToTop();
  }

  useScrollPosition(({ prevPos, currPos }) => {
    if (currPos.y <= -35 && hideHeader == false && !isMobile) {
      showHideHeader(true);
    } else if (currPos.y > -35 && hideHeader) {
      showHideHeader(false);
    }
  });

  //props.contenttype, props.contentitem, props.content
  function updateContentItemBREAK(row) {
    formdata = {};

    parseFormdata(row);

    setValues({
      ...values,
      formdata: formdata,
      isDisabled: true,
      buttonsDisabled: true,
      mode: "view",
    });

    const newWidgObj = {
      name: row.content,
      contenttype: row.contenttype,
      contentitem: row.contentitem,
      contentid: row.contentid,
      contentImg: row.contentImg,
      url: row.url,
      widgetoptions: row.widgetoptions,
    };

    const newState = update(placementItem, {
      content: { 0: { $set: newWidgObj } },
    });

    updatePlacementItem(newState);
  }

  function saveTimeSchedule(timeConditions, isEditing) {
    let contentoptionsNew = timeConditions;
    let id = editingPlacementId;
    if (!isEditing) {
      addPlacement(timeConditions);
    } else {
      const newState = update(placementItem, {
        content: { [id]: { contentoptions: { $set: contentoptionsNew } } },
      });
      updatePlacementItem(newState);
    }
    handleOpenTimeschedule();
  }

  const handleChangeContent = (name) => (event) => {
    let newValue = event.target.value;

    if (newValue && typeof newValue === "string") {
      if (newValue === "true") newValue = false;
      if (newValue === "false") newValue = true;
    }

    let formdataitem = {
      ...values.formdata[name],
      value: newValue,
    };
    let formdata = { ...values.formdata, [name]: { ...formdataitem } };

    setValues({ ...values, formdata: formdata });
  };

  function updatePlacementContent(item, id) {
    const newState = update(placementItem, {
      content: { [id]: { $set: item } },
    });

    updatePlacementItem(newState);
    updateBlockingStatus(true);
  }

  function createSelectItem(item) {
    return (
      <Grid item xs={4} key={item.name}>
        <TextField
          id={"filled-" + item.name}
          select
          label={item.label}
          className={classes.textField}
          value={values.formdata[item.paramname].value}
          onChange={handleChangeContent(item.paramname)}
          SelectProps={{
            MenuProps: {
              className: classes.menu,
            },
          }}
          InputLabelProps={{
            shrink: true,
          }}
          margin="normal"
          variant="filled"
          key={item.name}
          disabled={values.isDisabled}
        >
          {item.items.map((option) => (
            <MenuItem key={option.key} value={option.key}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    );
  }

  let timeSchedulePlacements = "";
  function renderPlacements(placements) {
    return placements.map((content, index) => {
      return (
        <Element key={index} name={"element-" + index}>
          <PlacementContent
            divWidth={dimensions.width}
            key={index}
            id={index}
            contentNr={placements.length}
            content={content}
            contentoptions={content.contentoptions}
            contentWidth={placementItem.width}
            contentWidthUnit={placementItem.width_unit}
            contentHeight={placementItem.height}
            contentHeightUnit={placementItem.height_unit}
            updatePlacementContent={updatePlacementContent}
            editTimeschedule={(e) => editTimeschedule(index)}
            handleDelete={(e) => handleDelete(index)}
            updateBlockingStatus={updateBlockingStatus}
          />
        </Element>
      );
    });
  }

  let newitems = [];
  function processData(data, selectedItem) {
    data.forEach(function (item, key) {
      if (item.type === "select") {
        if (
          item.hasChildren &&
          (selectedItem === item.key || selectedItem === "start")
        ) {
          newitems.push(createSelectItem(item, selectedItem));

          processData(item.items, values.formdata[item.paramname].value);
        } else if (!item.hasChildren) {
          newitems.push(createSelectItem(item, "start"));
        }
      } else if (item.type === "text") {
        newitems.push(
          <FormTextItem
            key={item.name}
            item={item}
            onChange={handleChangeContent}
            value={values.formdata[item.paramname].value}
            isDisabled={values.isDisabled}
          />
        );
      } else if (item.type === "checkbox") {
        newitems.push(
          <FormCheckbox
            key={item.name}
            item={item}
            onChange={handleChangeContent}
            value={values.formdata[item.paramname].value}
            isDisabled={values.isDisabled}
          />
        );
      }
    });

    return newitems;
  }

  function addPlacement(conentoptions) {
    setIsBlocking(true);
    let item = {
      name: "Nytt innhold",
      contenttype: "",
      contentitem: "",
      contentid: "",
      contentImg: "",
      url: "",
      contentoptions: conentoptions,
      widgetoptions: [],
    };

    let placementContent = placementItem.content.concat(item);
    updatePlacementItem({
      ...placementItem,
      content: placementContent,
    });

    const nrPlacements = placementContent.length - 2;

    setAddNewPlacement(true);
  }
  const statsRange = React.useContext(StatsContext);
  const statsInterval = statsRange.state;
  const statsPartnerCode = statsRange.partnerCode;

  useEffect(() => {
    const savePlacement = async (id) => {
      if (!statsRange.sid) return;
      const result = await axios.put(
        "https://api.norsktippingpartner.net/position/" +
          id +
          "?session_key=" +
          statsRange.sid,
        {
          ...updateRow,
        }
      );

      let results = result.data;

      //console.log("results", results);
      if (!placementItem.id) {
        const newrow = { ...updateRow, id: results.position_id };
        updatePlacementItem({ ...placementItem, ...newrow });
        //props.history.push("/placements/edit/" + results.position_id);
      }
    };
    if (updateRow) {
      savePlacement(updateRow.id);
    }
  }, [updateRow, statsRange.sid, placementItem]);

  const updatePlacement = (placementProps) => {
    const newData = { ...placementItem, ...placementProps };

    setUpdateRow(newData);

    //props.history.push('/dashboard');
  };

  //processData(placementItem.content[0].widgetoptions, "start");
  timeSchedulePlacements = renderPlacements(placementItem.content);

  const clicks =
    placementItem.stats && placementItem.stats[statsInterval]
      ? placementItem.stats[statsInterval].clicks
      : 0;
  const clicks_trend =
    placementItem.stats && placementItem.stats[statsInterval]
      ? placementItem.stats[statsInterval].clicks_trend
      : "";
  const ctr =
    placementItem.stats && placementItem.stats[statsInterval]
      ? placementItem.stats[statsInterval].ctr
      : 0;
  const ctr_trend =
    placementItem.stats && placementItem.stats[statsInterval]
      ? placementItem.stats[statsInterval].ctr_trend
      : "";
  const views =
    placementItem.stats && placementItem.stats[statsInterval]
      ? placementItem.stats[statsInterval].views
      : 0;
  const views_trend =
    placementItem.stats && placementItem.stats[statsInterval]
      ? placementItem.stats[statsInterval].views_trend
      : "";

  return (
    <>
      <Container maxWidth="xl" className={classes.container} ref={targetRef}>
        <Grid container spacing={0}>
          {/* Favorite placements */}

          <Grid item xs={12}>
            <PlacementProperties
              divWidth={dimensions.width}
              id={placementItem.id}
              placement={placementItem.placement}
              cat={placementItem.content[0].name}
              width={placementItem.width}
              width_unit={placementItem.width_unit}
              height={placementItem.height}
              height_unit={placementItem.height_unit}
              tag={placementItem.tag}
              clicks={clicks}
              clicks_trend={clicks_trend}
              ctr={ctr}
              ctr_trend={ctr_trend}
              views={views}
              views_trend={views_trend}
              key={placementItem.id}
              favourite={placementItem.favourite}
              handleChange={handleChange}
              handleChangeTag={handleChangeTag}
              handleChangePlacementContent={handleChangePlacementContent}
              generateURL={generateURL}
              hideHeader={hideHeader}
              showHideHeader={showHideHeader}
              isDisabled={values.isDisabled}
              buttonsDisabled={values.buttonsDisabled}
              handleOpenTimeschedule={(e) => editTimeschedule(0)}
              updateBlockingStatus={updateBlockingStatus}
              handleDelete={handleDeleteWholePlacement}
              updatePlacement={updatePlacement}
              updatePlacementPropsDimensions={updatePlacementProps}
              updatePlacementPropsDimensionsMulti={updatePlacementPropsMulti}
              getPlacementURL={getPlacementURL}
            />
          </Grid>
        </Grid>

        <>
          <Grid item xs={12} className={classes.timeScheduleHeading}>
            <Typography variant="h3" component="h6" align="left">
              <Schedule className={classes.bigIcon} onClick={props.onTSclick} />{" "}
              Innholdskø
            </Typography>
          </Grid>
        </>

        {timeSchedulePlacements}

        {hideHeader && (
          <Grid item xs={12} className={classes.addToScroll}></Grid>
        )}
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={open}
          autoHideDuration={2000}
          onClose={handleCloseSnack}
        >
          <MySnackbarContentWrapper
            onClose={handleCloseSnack}
            variant="success"
            message={snackMsg}
          />
        </Snackbar>
      </Container>
      {timeScheduleOpen && (
        <TimeScheduleDialog
          open={timeScheduleOpen}
          handleClose={handleOpenTimeschedule}
          handleChange={props.handleChange}
          saveTimeSchedule={saveTimeSchedule}
          placementSchedule={
            editingPlacementId
              ? placementItem.content[editingPlacementId]
              : null
          }
        />
      )}
      <AlertDialog
        open={alertOpen > 0}
        handleClose={handleCloseAlert}
        handleConfirm={confirmDelete}
        msg="Er du sikker på at du vil slette dette innholdet?"
      />

      <AlertDialog
        open={alertOpenItem > 0}
        handleClose={handleCloseAlertItem}
        handleConfirm={confirmDeleteItem}
        msg="Er du sikker på at du vil slette plasseringen?"
      />
      <Prompt
        when={isBlocking}
        message={(location) =>
          `Er du sikker at du vil forlatte siden? Du har ikke lagret endringer!`
        }
      />
    </>
  );
}
export default withRouter(PlacementsEditItem);
