import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import PlacementIcon from "@material-ui/icons/FeaturedVideoOutlined";

import StatsContext from "../../context/StatsContext";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import ContentProperties from "./components/ContentProperties";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Snackbar from "@material-ui/core/Snackbar";
import MySnackbarContentWrapper from "../../components/MySnackbarContentWrapper";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import palette from "../../theme/palette";

import AlertDialog from "../../components/AlertDialog";

import useAxios from "axios-hooks";
import ElementItem from "../../components/ElementItem";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
      paddingTop: theme.spacing(0),
    },
  },
  paper: {
    padding: theme.spacing(3),
    display: "flex",
    overflow: "auto",
    flexDirection: "row",
    flexWrap: "wrap",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    boxShadow:
      "0px 3px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
  },
  button: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  fixedHeight: {
    height: 240,
  },
  iconStyles: {
    verticalAlign: "middle",
    marginRight: theme.spacing(1),
  },
  heading: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  modal: {
    position: "absolute",
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: "none",
  },
  gridFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "10px 8px",
  },
  flexCent: {
    display: "flex",
    paddingLeft: "28px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
    },
  },
  menuItems: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  card: {
    minWidth: 275,
    width: "100%",
    boxShadow: "none",
    textAlign: "center",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  pos: {
    marginBottom: 12,
  },
  media: {
    height: 258,
    width: 450,
    margin: "0 auto",
  },
  bigIcon: {
    verticalAlign: "middle",
    width: 24,
    height: 24,
    color: palette.text.secondary,
  },
  outerPadding: {
    padding: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      padding: "0 20px",
    },
  },
  buttonLeft: {
    marginRight: "1.6rem",
  },
  cardListIcon: {
    marginRight: "24px",
    [theme.breakpoints.down("xs")]: {
      marginRight: "12px",
    },
    width: 22,
    height: 22,
  },
}));

function ContentEdit(props) {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:767px)");
  const iframeWidth = isMobile ? 280 : 480;
  let iframeHeight = isMobile ? 280 : 400;
  let cIddef = props.match.params.id > 0 ? props.match.params.id : "";
  const isNewDefault = props.match.path.indexOf("new") !== -1 ? true : false;
  const [isNew, setIsNew] = React.useState(isNewDefault);
  const [deleteURL, setDeleteURL] = useState("");

  const statsRange = React.useContext(StatsContext);
  const statsInterval = statsRange.state;
  const statsPartnerId = statsRange.partnerId;
  const [cId, setCid] = React.useState(cIddef);
  const [values, setValues] = React.useState(null);
  const [saveNew, setSaveNew] = React.useState(null);
  const [saveItem, setSaveItem] = React.useState(null);

  const [addNewPlacement, setaddNewPlacement] = React.useState(null);
  const [alertOpen, setAlertOpen] = useState(null);
  const [contentItem, updateContentItem] = useState([]);

  const [selectedCoupon, setSelectedCoupon] = useState([]);

  function handleOpenAlert(id) {
    setAlertOpen(id);
  }

  function generateURL(values) {
    let urlString = "";

    for (var k in values.formdata) {
      if (
        values.formdata.hasOwnProperty(k) &&
        values.formdata[k] !== "" &&
        values.formdata[k]
      ) {
        urlString += k + "=" + values.formdata[k].value + "&";
      }
    }
    let Coupon = "";

    if (selectedCoupon.length > 0) {
      Coupon = "&rekker=" + selectedCoupon.join();
      console.log("selectedCoupon", selectedCoupon);
    }
    if (isNew) {
      urlString += "p_id=" + statsPartnerId + "&c_id=-1";
    } else {
      urlString += "p_id=" + statsPartnerId + "&isa=1&c_id=" + cId + Coupon;
    }
    // console.log("generating url");
    if (values.url.includes("Widget30v2021")) {
      iframeHeight = isMobile ? 400 : 800;
      console.log("iframeHeight 1", iframeHeight);
    }
    return "https://widget.tippebannere.no/v3/" + values.url + urlString;
  }

  let loadURL = isNew
    ? "https://api.norsktippingpartner.net/content/templates/" +
      cId +
      "?session_key=" +
      statsRange.sid
    : "https://api.norsktippingpartner.net/content/" +
      cId +
      "?session_key=" +
      statsRange.sid;

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(loadURL);
      let results = result.data;

      if (isNew) {
        let resultTemplate = result.data.contentTypes.filter(function (item) {
          return item.template_id == cId;
        });
        results = resultTemplate[0];
      }
      updateContentItem(results);
      initItem(results);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (!saveNew) return;

    if (contentItem.name.length < 1) {
      console.log("error");

      setSnackMsg("Du må skrive en tittel!");
      setSnackMode("error");
      setOpen(true);
      setSaveNew(false);
      setIsNew(true);
      return;
    }
    const saveNewContentItem = async () => {
      const result = await axios.post(
        "https://api.norsktippingpartner.net/content/" +
          "?session_key=" +
          statsRange.sid,
        {
          ...contentItem,
        }
      );

      let results = result.data;

      if (!contentItem.id) {
        const newrow = { ...contentItem, id: results.content_id };
        setCid(results.content_id);
        updateContentItem({ ...contentItem, ...newrow });
        setSaveNew(false);
        setIsNew(false);
        if (addNewPlacement) {
          props.history.push("/newplacement/" + results.content_id);
        }
        //props.history.push("/placements/edit/" + results.position_id);
      }
    };
    saveNewContentItem();
  }, [saveNew]);

  useEffect(() => {
    if (!saveItem) return;

    if (contentItem.name.length < 1) {
      console.log("error");

      setSnackMsg("Du må skrive en tittel!");
      setSnackMode("error");
      setOpen(true);
      setSaveNew(false);
      setIsNew(true);
      return;
    }
    const saveContentItem = async () => {
      const result = await axios.put(
        "https://api.norsktippingpartner.net/content/" +
          contentItem.id +
          "?session_key=" +
          statsRange.sid,
        {
          ...contentItem,
        }
      );

      let results = result.data;

      setSaveItem(false);

      if (addNewPlacement) {
        props.history.push("/newplacement/" + results.content_id);
      }
    };
    saveContentItem();
  }, [saveItem]);

  const [
    { data: loginData, loading: isLoading, error: isError },
    saveContentItemOld,
  ] = useAxios(
    {
      url:
        "https://api.norsktippingpartner.net/content/" +
        cId +
        "?session_key=" +
        statsRange.sid,
      method: "PUT",
    },
    { manual: true }
  );

  function doDelete(itemId) {
    setDeleteURL(
      "https://api.norsktippingpartner.net/content/" +
        itemId +
        "?session_key=" +
        statsRange.sid
    );
  }

  React.useEffect(() => {
    const deleteData = async () => {
      if (deleteURL != "") {
        try {
          const response = await axios.delete(deleteURL);
          gotoContentListPage();
          // console.log("👉 Returned data:", response);
        } catch (e) {
          console.log(`😱 Axios request failed: ${e}`);
        }
      }
    };
    deleteData();
  }, [deleteURL]);

  const [openModal, handleOpen] = React.useState(false);

  const [open, setOpen] = React.useState(false);

  const [snackMsg, setSnackMsg] = React.useState("Oppdatert");
  const [snackMode, setSnackMode] = React.useState("success");

  function confirmDelete() {
    deleteContent(alertOpen);
    handleOpenAlert(null);
  }
  function handleCloseAlert() {
    setAlertOpen(false);
  }

  function deleteContent(id) {
    doDelete(id);
    // gotoContentListPage();
  }

  function gotoContentListPage() {
    props.history.push("/content");
  }

  function handleCloseSnack(event) {
    setOpen(false);
  }

  function saveContent() {
    updateData();

    //console.log(contentItem.widgetoptions);

    if (isNew) {
      setSaveNew(true);
    } else {
      setSaveItem(true);
    }
    setSnackMsg("Oppdatert");
    setSnackMode("success");
    setOpen(true);
  }
  function updateTipping(coupon, widgetoptions) {
    const widgetcoupons = widgetoptions.filter(function (row) {
      return row.type === "tippekupong";
    });

    let selectedCoupon = values.formdata["dag"].value;

    for (var i = 0; i < widgetcoupons[0].items.length; i++) {
      if (widgetcoupons[0].items[i].key === parseInt(selectedCoupon, 10)) {
        updateCoupon(widgetcoupons[0].items[i].coupon, coupon);
      }
    }

    console.log("updating tipping", widgetoptions, selectedCoupon);
  }

  function updateCoupon(widgetcoupon, coupon) {
    console.log("-", widgetcoupon, coupon);

    for (var i = 0; i < widgetcoupon.length; i++) {
      widgetcoupon[i].selection = coupon[i];
    }
    setSelectedCoupon([...coupon]);
  }
  function updateData() {
    for (var k in values.formdata) {
      if (values.formdata.hasOwnProperty(k) && values.formdata[k] !== "") {
        //  console.log("%c LOOPING FORMDATA ", "background: red; color: #fff", k);
        if (k === "coupon") {
          updateTipping(values.formdata[k], contentItem.widgetoptions);
        } else {
          updateDataItem(
            values.formdata[k],
            contentItem.widgetoptions,
            "start"
          );
        }
      }
    }
  }
  /*CHECK THIS - YOU NEED TO UPDATE contentItem.widgetoptions */
  function updateDataItem(formdataItem, arr, sub, patharr) {
    //console.log("updateDataItem", arr, formdataItem, sub, patharr);
    let found = false;
    if (!arr) return;
    if (!patharr) patharr = [];
    let widgetItem = arr.filter(function (item) {
      return item.paramname == formdataItem.paramname;
    });
    //FOUND MATCH
    if (widgetItem.length > 0 && widgetItem[0].value) {
      /*console.log(
        "%c update WIDGET value",
        "background: #bada55; color: #fff",
        formdataItem,
        widgetItem[0].value
      );*/
      widgetItem[0].value[0] = formdataItem.value + "";
      return;
    } else {
      for (let i = 0; i < arr.length; i++) {
        let newArr = arr[i];

        //console.log("KEY", formdataItem.parentKey, newArr.key, newArr);
        if (newArr.paramname == formdataItem.paramname) {
          //  console.log("SAME PARAM NAME", formdataItem, newArr);
          //FOUND MATCH
          if (findItemById(newArr.items, formdataItem.value)) {
            newArr.value = [formdataItem.value];
            found = true;
            /*  console.log(
              "%c FOUND MATCH IN ITEMS",
              "background: #bada55; color: #fff",
              formdataItem,
              newArr.value
            );*/
            break;
          } else if (!newArr.items) {
            newArr.value = [formdataItem.value];
            /* console.log(
              "%c FOUND MATCH",
              "background: #bada55; color: #fff",
              formdataItem,
              newArr.value
            );*/
            found = true;
            break;
          }
        } else {
          if (newArr.items) {
            patharr.push(newArr.paramname);
            updateDataItem(formdataItem, newArr.items, "sub", patharr);
          }
          //  console.log("NOT FOUND - NEXT LEVEL", formdataItem, newArr);
        }
        //}
      }
    }
  }

  function addToNewPlacement() {
    const isTemplate = isNew ? "t/" : "";

    const id = contentItem.id ? contentItem.id : cId;
    const placementUrl = isTemplate + id;
    setaddNewPlacement(placementUrl);
    saveContent();
  }

  let formdata = {};
  function getTippingDay() {
    var d = new Date();
    var n = d.getDay();

    if (n === 0) {
      return 9;
    } else if (n > 0 && n < 4) {
      return 10;
    } else {
      return 8;
    }
  }
  function parseFormdata(data, parentName, lvl, parentKey, mainParent) {
    if (!data) return;
    const level = lvl ? lvl : 0;
    //console.log(data, parentName, lvl, parentKey, mainParent);
    for (let i = 0; i < data.length; i++) {
      let item = data[i];

      if (item.paramname && item.value) {
        let itemObj = {};
        let defaultValue = item.value;
        //console.log("item.paramname", item.paramname);
        if (Array.isArray(defaultValue) === false && item.items) {
          defaultValue = item.items[0].key;
        } else if (Array.isArray(defaultValue)) {
          defaultValue = defaultValue[0];
        }
        itemObj.value = defaultValue;
        itemObj.name = item.name;
        itemObj.paramname = item.paramname;
        itemObj.parentName = parentName;
        itemObj.parentKey = parentKey;
        itemObj.level = level;
        itemObj.key = parentKey;
        if (level === 0) {
          mainParent = defaultValue;
        }

        itemObj.mainParent = mainParent;
        if (item.items && item.paramname === "tippekupong") {
          parseTippekupong(item.items, defaultValue);
          formdata[item.paramname] = itemObj;
        } else if (item.items) {
          console.log("items", item.paramname);
          itemObj.childName = item.items[0].paramname;
          if (item.paramname === "dag") {
            item.value[0] = getTippingDay();
            itemObj.value = getTippingDay();
            item.mainParent = getTippingDay();
            console.log("DAAAG", item.value[0]);
          }
          formdata[item.paramname] = itemObj;
          parseFormdata(
            item.items,
            item.paramname,
            level + 1,
            item.value[0],
            mainParent
          );
        } else {
          formdata[item.paramname] = itemObj;
        }
      }
    }
  }

  function parseTippekupong(items, defaultValue) {
    const coupon = items.filter(function (row) {
      return row.key == defaultValue;
    });
    //console.log("tippekuppong", defaultValue, coupon[0].coupon);
    let couponSelection = [];
    for (var i = 0; i < coupon[0].coupon.length; i++) {
      couponSelection.push(coupon[0].coupon[i].selection);
    }
    formdata["coupon"] = couponSelection;
  }

  function initItem(contentItem) {
    if (!contentItem) return;

    parseFormdata(contentItem.widgetoptions);

    setValues({
      ...values,
      url: contentItem.url,
      formdata: formdata,
      isDisabled: false,
      mode: "view",
    });
  }

  const handleTitle = (name) => (event) => {
    let newValue = event.target.value;

    updateContentItem({ ...contentItem, [name]: newValue });
  };

  const handleRemoveItem = (name) => {
    const newFormdata = { ...values.formdata, [name]: undefined };

    // setValues({ ...values, formdata: newFormdata });
  };
  var newFormdata = {};
  //reset all levels under the changed select, add new formdata if it does not exits

  function resetFormdata(
    data,
    name,
    newValue,
    parentName,
    lvl,
    parentKey,
    mainParent
  ) {
    const level = lvl ? lvl : 0;
    /* console.log(
      "ENTER RESET ",
      data,
      name,
      newValue,
      parentName,
      lvl,
      parentKey,
      mainParent
    );*/
    //data.forEach(function(item, key) {
    let key = newValue;
    for (let i = 0; i < data.length; i++) {
      let item = data[i];
      if (item.value) {
        // console.log("RESET ", item, parentKey);
        let itemObj = {};
        let defaultValue = -1;
        item.value = null;
        //newFormdata[item.paramname] = -1;
        if (item.items) {
          resetFormdata(
            item.items,
            item.paramname,
            defaultValue,
            item.name,
            level + 1,
            newValue
          );
        }
      } else {
        //add new formdata if it does not exits
        if (item.items) {
          addFormdata(
            item,
            item.paramname,
            newValue,
            item.name,
            level + 1,
            newValue
          );
        } else {
          addFormdata(item, item.paramname, "", item.paramname, level + 1, "");
        }
      }
    }
  }
  function addFormdata(data, name, newValue, parentName, lvl, parentKey) {
    /*   console.log(
      "addFormdata",
      data,
      name,
      newValue,
      parentName,
      lvl,
      parentKey
    );*/

    //console.log("DATA CHECK", data.key, parentKey);
    if (data.key.toString() != parentKey.toString()) return;
    const level = lvl ? lvl : 0;
    let defaultValue = data.key;
    let itemObj = {};
    if (!data.value && data.items) {
      defaultValue = data.items[0].key;
    }
    itemObj.value = defaultValue;
    itemObj.name = data.name;
    itemObj.paramname = data.paramname;
    itemObj.parentName = parentName;
    itemObj.level = level;
    itemObj.key = parentKey;
    // console.log("ADDING KEY", itemObj, data, parentKey);
    newFormdata[data.paramname] = itemObj;
    if (data.items) {
      resetFormdata(
        data.items,
        data.paramname,
        defaultValue,
        data.name,
        level + 1,
        newValue
      );
    }
  }

  function findItemById(data, id) {
    if (!data) return false;
    let found = false;
    for (let i = 0; i < data.length; i++) {
      let item = data[i];
      if (item.key === id) {
        found = true;
        //console.log("FOUND", item, id);
        break;
      }
    }
    return found;
  }

  //CHECK THIS ONE TOO
  //updateFormadata(contentItem.widgetoptions, name, newValue, parentName);

  function updateFormadata(
    data,
    name,
    newValue,
    parentName,
    lvl,
    parentKey,
    mainParent
  ) {
    const level = lvl ? lvl : 0;

    if (!lvl) {
      console.log(
        "ROOT UPDATING",
        data,
        name,
        newValue,
        parentName,
        lvl,
        parentKey,
        mainParent
      );
    } else {
      console.log(
        "---- CHILD UPDATING LEVEL " + lvl,
        data,
        name,
        newValue,
        parentName,
        lvl,
        parentKey,
        mainParent
      );
    }
    if (!parentKey && parentName && values.formdata[parentName]) {
      parentKey = values.formdata[parentName].value;
    }

    let found = false;
    //console.log("parentKey", parentKey);
    for (let i = 0; i < data.length; i++) {
      //data.forEach(function(item, key) {
      let item = data[i];

      if (name && item.paramname == name) {
        let itemObj = {};
        /*     console.log(
          "---- ITEM NAME " + name + " " + item.paramname + " LEVEL " + lvl,
          item
        );*/
        let defaultValue = newValue;

        itemObj.value = defaultValue;
        itemObj.name = item.name;
        itemObj.paramname = item.paramname;
        itemObj.parentName = parentName;
        itemObj.level = level;

        itemObj.parentKey = parentKey;
        itemObj.level = level;
        itemObj.key = parentKey;

        if (level === 0) {
          mainParent = defaultValue;
        }

        itemObj.mainParent = mainParent;

        itemObj.key = parentKey;
        let check = findItemById(item.items, defaultValue);
        if (item.items) {
          itemObj.childName = item.items[0].paramname;
        }
        if (item.type != "select") {
          check = true;
        }
        //MATCH FOUND UPDATE FORMDATA
        if (check === true) {
          //|| !item.items
          const oldData = newFormdata[item.paramname];
          //console.log("OLD DATA", oldData);
          newFormdata[item.paramname] = itemObj;
          /*console.log(
            "UPDATING",
            defaultValue,
            item,
            item.name,
            newFormdata[item.paramname],
            parentKey,
            mainParent
          );*/
          found = true;

          //RESET ALL UNDER
          if (item.items) {
            /*console.log("ITEM", item.items[defaultValue].items[0]);
          if (!item.items[defaultValue].value) {
            item.items[defaultValue].value = [
              "" + item.items[defaultValue].items[0]["key"]
            ];
          }*/

            /*    console.log(
              "RESET FORMDATA",
              item.items,
              item.paramname,
              defaultValue,
              item.name,
              level + 1
            );*/

            resetFormdata(
              item.items,
              item.paramname,
              defaultValue,
              item.paramname,
              level + 1,
              parentKey,
              item.mainParent
            );
          }
          break;
        }
      } else {
        //CHECK HERE IF THE MAIN PARENT IS SAME
        if (item.items && !found) {
          //   console.log("---- LEVEL " + (level + 1) + "-----", found);

          updateFormadata(
            item.items,
            name,
            newValue,
            item.paramname,
            level + 1,
            parentKey,
            item.mainParent
          );
        }
      }
    }

    // console.log("----EXIT updateFormadata-----");
  }

  function deleteFormadata(name, newValue, parentName) {
    //console.log("DELETE FORMDATA", name, newValue, parentName);

    newFormdata = { ...values.formdata };
    updateFormadata(contentItem.widgetoptions, name, newValue, parentName);

    setValues({ ...values, formdata: newFormdata });
    //console.warn(newFormdata);
    //handleRemoveItem(name);
    /*
    Object.keys(values.formdata).forEach(function(element, index) {
      let item = values.formdata[element];
      console.log("element", item);
      if (element == name || parentNameArr.indexOf(element)) {
        parentNameArr.push(element);
      } else {
        return;
      }
      if (item.level > paramLevel) {
        let formdataitem = {
          ...item,
          value: -1
        };
        console.log("ITEM LEVEL", item.level, formdataitem);
        formdataUpdated = {
          ...formdataUpdated,
          [item.paramname]: { ...formdataitem }
        };
      }
    });*/
  }

  const handleChange = (name, parentName) => (event) => {
    let newValue = event.target.value;
    console.log("CHANGE", newValue);
    //console.log(event, name, parentName);
    if (newValue && typeof newValue === "string") {
      if (newValue === "true") newValue = false;
      if (newValue === "false") newValue = true;
    }
    /*  let formdataUpdated = { ...values.formdata };
    let formdata;
    let paramLevel = values.formdata[name].level;
    let parentNameArr = [];
    */
    deleteFormadata(name, newValue, parentName);

    //updateData();
    //parseFormdata(contentItem.widgetoptions);
    /*

    let formdataitem = {
      ...formdataUpdated[name],
      value: newValue
    };
    console.log("name", name, "value", newValue);

    formdata = { ...formdataUpdated, [name]: { ...formdataitem } };

    setValues({ ...values, formdata: formdata });
    */
  };

  function handleDelete(itemId) {
    console.log(itemId);
  }

  function handleContentDelete() {
    handleOpenAlert(cId);
  }

  function handleOpenDialog() {
    console.log("open");
  }
  function addToFavorites() {
    console.log("favorites");
  }
  console.log("iframeHeight 2", iframeHeight);
  console.log("iframeWidth 2", iframeWidth);
  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      {values && (
        <Container maxWidth="xl" className={classes.container}>
          <Grid container spacing={3}>
            {/* Favorite placements */}

            <Grid item xs={12} className={classes.outerPadding}>
              <ContentProperties
                name={contentItem.name}
                widgetoptions={contentItem.widgetoptions}
                formdata={values.formdata}
                mode={values.mode}
                handleChange={handleChange}
                handleTitle={handleTitle}
                updateTipping={updateTipping}
                isDisabled={values.isDisabled}
              />

              <Paper className={classes.paper}>
                {values.mode === "view" && (
                  <Grid item xs={12} className={classes.gridFlex}>
                    {iframeWidth > 0 && iframeHeight > 0 && (
                      <Card className={classes.card}>
                        <iframe
                          title="preview"
                          id="contentFrame"
                          frameBorder="0"
                          scrolling="no"
                          src={generateURL(values)}
                          style={{
                            width: iframeWidth + "px",
                            height: iframeHeight + "px",
                          }}
                        ></iframe>
                      </Card>
                    )}
                  </Grid>
                )}

                <Grid item xs={12} align="right">
                  <Button
                    variant="contained"
                    color="secondary"
                    className={clsx(classes.button, classes.buttonLeft)}
                    onClick={gotoContentListPage}
                  >
                    Avbryt
                  </Button>{" "}
                  <Button
                    variant="contained"
                    color="secondary"
                    className={clsx(classes.button, classes.buttonLeft)}
                    onClick={handleContentDelete}
                    disabled={
                      contentItem.positions && contentItem.positions.length > 0
                    }
                  >
                    Slett
                  </Button>{" "}
                  <Button
                    variant="contained"
                    color="secondary"
                    className={clsx(classes.button, classes.buttonLeft)}
                    onClick={addToNewPlacement}
                  >
                    NY PLASSERING MED INNHOLD
                  </Button>{" "}
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={saveContent}
                  >
                    LAGRE INNHOLD
                  </Button>{" "}
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            {/* Favorite placements */}

            <Grid item xs={12} className={classes.outerPadding}>
              {contentItem.positions && contentItem.positions.length > 0 && (
                <Grid item xs={12} className={classes.rowMargin}>
                  <Typography
                    variant="h3"
                    component="h3"
                    align="left"
                    className={classes.heading}
                  >
                    <span className={classes.flexCent}>
                      <PlacementIcon className={classes.cardListIcon} />
                      Plasseringer med {contentItem.name}
                    </span>
                  </Typography>

                  {contentItem.positions.map((row) => {
                    const statObj = row.stats[statsInterval];

                    const sClicks = statObj ? statObj.clicks : 0;
                    const sClicksTrend = statObj ? statObj.clicks_trend : "";
                    const sCtr = statObj ? statObj.ctr : 0;
                    const sCtrTrend = statObj ? statObj.ctr_trend : "";
                    const sViews = statObj ? statObj.views : 0;
                    const sViewsTrend = statObj ? statObj.views_trend : 0;
                    return (
                      <ElementItem
                        key={row.id}
                        type={row.type}
                        placement={row.placement}
                        content={contentItem.name}
                        contentNr={0}
                        clicknr={sClicks}
                        clicks_trend={sClicksTrend}
                        ctr={sCtr}
                        ctr_trend={sCtrTrend}
                        displays={sViews}
                        displays_trend={sViewsTrend}
                        tag={row.tag}
                        url="/placements/edit/"
                        preview_url={row.url}
                        id={row.id}
                        favourite={row.favourite}
                        zeroMinWidth
                        handleDelete={handleDelete}
                        handleOpenDialog={handleOpenDialog}
                        addToFavorites={addToFavorites}
                      />
                    );
                  })}
                </Grid>
              )}
            </Grid>
          </Grid>

          <AlertDialog
            open={alertOpen > 0}
            handleClose={handleCloseAlert}
            handleConfirm={confirmDelete}
            msg="Er du sikker på at du vil slette dette innholdet?"
          />
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={open}
            autoHideDuration={2000}
            onClose={handleCloseSnack}
          >
            <MySnackbarContentWrapper
              onClose={handleCloseSnack}
              variant={snackMode}
              message={snackMsg}
            />
          </Snackbar>
        </Container>
      )}
    </main>
  );
}
export default withRouter(ContentEdit);
