import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";

import axios from "axios";

import PlacementsEditItem from "./components/PlacementsEditItem";
import StatsContext from "../../context/StatsContext";

const useStyles = makeStyles(theme => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh"
  }
}));

function PlacementsEdit(props) {
  const classes = useStyles();
  const pId = props.match.params.id > 0 ? props.match.params.id : "";
  const [dataContent, setData] = useState(null);
  const [dataPosition, setDataPosition] = useState(null);

  const isNew = props.match.path.indexOf("newplacement") === -1 ? false : true;
  const isNewTemplate = props.match.path.indexOf("/t/") === -1 ? false : true;

  const statsRange = React.useContext(StatsContext);
  const hasNewContent = isNew && props.match.params.id > 0 ? true : false;

  //const isNew = pId === "" ? true : false;
  /*
  const [{ data, loading: isLoading, error: isError }, loadPosition] = useAxios(
    {
      url:
        "https://api.norsktippingpartner.net/position/" +
        pId +
        "?session_key=" +
        statsRange.sid,
      method: "GET"
    },
    { manual: isNew }
  );
*/
  /*
  const [
    { dataContent, loading: isLoadingContent, error: isErrorContent },
    loadContent
  ] = useAxios(
    {
      url:
        "https://api.norsktippingpartner.net/content/" +
        pId +
        "?session_key=" +
        statsRange.sid,
      method: "GET"
    },
    { manual: !hasNewContent }
  );

  */
  const urlPosition =
    "https://api.norsktippingpartner.net/position/" +
    pId +
    "?session_key=" +
    statsRange.sid;

  React.useEffect(() => {
    const fetchData = async () => {
      const result = await axios(urlPosition);
      setDataPosition(result.data);
    };
    if (isNew === false) {
      fetchData();
    }
  }, [isNew, urlPosition]);

  const templUrl = isNewTemplate ? "templates/" : "";
  const url =
    "https://api.norsktippingpartner.net/content/" +
    templUrl +
    pId +
    "?session_key=" +
    statsRange.sid;

  React.useEffect(() => {
    const fetchData = async () => {
      const result = await axios(url);

      if (isNewTemplate) {
        setData(result.data.contentTypes[0]);
      } else {
        setData(result.data);
      }
    };
    if (hasNewContent === true) {
      fetchData();
    }
  }, [hasNewContent, isNewTemplate, url]);

  /*
   const url =
    props.type === "nytt"
      ? "https://api.norsktippingpartner.net/content/templates?session_key=" +
        statsRange.sid
      : "https://api.norsktippingpartner.net/content?session_key=" + statsRange.sid;
  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(url);

      if (props.type === "nytt") {
        setData(result.data.contentTypes);
        parseFilter(result.data.contentTypes);
        parseFilterRows(result.data.contentTypes);
      } else {
        setData(result.data.content);
        parseFilter(result.data.content);
        parseFilterRows(result.data.content);
      }
    };
    fetchData();
  }, []);
*/
  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      {((dataContent && dataContent.id) ||
        (dataContent && dataContent.template_id) ||
        (dataPosition && dataPosition.id) ||
        (isNew === true && hasNewContent === false)) && (
        <PlacementsEditItem
          item={dataPosition}
          isNew={isNew}
          pId={pId}
          itemcontent={dataContent}
        />
      )}
    </main>
  );
}
export default withRouter(PlacementsEdit);
