import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import axios from "axios";

import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import CopyToClipboard from "react-copy-to-clipboard";
import MenuStats from "../../../components/MenuStats";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import StarIconOutlined from "@material-ui/icons/GradeOutlined";
import StarIcon from "@material-ui/icons/Grade";
import StatsContext from "../../../context/StatsContext";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import AutocompleteCreate from "../../../components/AutocompleteCreate";
import palette from "../../../theme/palette";
import Snackbar from "@material-ui/core/Snackbar";
import MySnackbarContentWrapper from "../../../components/MySnackbarContentWrapper";
import Schedule from "@material-ui/icons/Schedule";
import MoreVert from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";

import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DeleteIcon from "@material-ui/icons/Delete";

import IconButton from "@material-ui/core/IconButton";

import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 120,
    maxWidth: 300,
    color: palette.common.white
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  textFieldInverse: {
    margin: 0,

    backgroundColor: "rgba(255, 255, 255, 0.24)",
    "& .MuiInputLabel-root": {
      color: palette.common.white
    },
    "& .MuiFilledInput-root": {
      color: palette.common.white
    },
    "& .MuiFilledInput-root.Mui-disabled": {
      backgroundColor: palette.primary.main
    },

    "& .MuiInputBase-input.Mui-disabled": {
      color: palette.common.white
    },
    "& .MuiFilledInput-underline:before": {
      borderBottom: "1px solid " + palette.common.grey
    },
    "& .Mui-disabled.MuiFilledInput-underline:before": {
      borderBottom: "none"
    },
    "& .MuiSelect-root": {
      color: palette.common.white
    },
    "& .MuiSelect-icon": {
      color: palette.common.white
    }
  },
  chip: {
    margin: theme.spacing(0) / 4
  },
  noLabel: {
    marginTop: theme.spacing(3)
  },
  buttonLeft: {
    marginRight: "1.6rem",
    color: "#fff",
    "&.Mui-disabled": {
      border: "1px solid #999",
      color: "#999"
    }
  },
  paper: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    display: "flex",

    flexDirection: "column",

    color: palette.common.white,
    backgroundColor: palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      borderRadius: 0,
      marginLeft: 0,
      boxShadow: "none"
    }
  },
  heading: {
    color: palette.common.white
  },
  placementHeading: {
    display: "flex",
    alignContent: "center"
  },
  formElementHolder: {
    marginTop: "3rem",
    marginBottom: "4rem",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0rem"
    }
  },
  alignRight: {
    textAlign: "right"
  },
  medFnt: {
    fontWeight: 600
  },
  pfixed: {
    [theme.breakpoints.down("sm")]: {
      display: "none"
    },
    [theme.breakpoints.up("sm")]: {
      position: "fixed",
      width: "inherit",
      top: "60px",
      zIndex: 10000
    }
  },
  mTop: {
    [theme.breakpoints.up("sm")]: {
      marginTop: "10px"
    }
  },
  iconRightMarg: {
    marginRight: theme.spacing(1)
  },
  hasCursor: {
    cursor: "pointer"
  },
  isFavorite: {
    color: palette.error.dark
  },
  mobbtn: {
    [theme.breakpoints.down("sm")]: {
      width: `calc(50% - 1.6rem)`
    }
  },
  mobPadFirst: {
    padding: "0 8px 0 0 !important",
    [theme.breakpoints.down("sm")]: {
      padding: "28px 4px 0 4px !important"
    }
  },
  mobPad: {
    padding: "0 8px 0 0 !important",
    [theme.breakpoints.down("sm")]: {
      padding: "28px 4px 0px 4px !important"
    }
  },
  bigIcon: {
    width: 24,
    height: 24,
    color: palette.common.white
  },
  buttonMenu: {
    padding: "0"
  },
  menuBar: {
    paddingTop: "20px",
    marginLeft: "4px"
  },
  menuButtons: {
    display: "flex",
    justifyContent: "flex-end"
  }
}));

const units = [
  {
    value: "px",
    label: "px"
  },
  {
    value: "%",
    label: "%"
  }
];

function PlacementProperties(props) {
  const classes = useStyles();

  const statsRange = React.useContext(StatsContext);

  const placementDefaultProperties = {
    placement: props.placement,
    cat: props.cat,
    tag: props.tag,
    width: props.width,
    width_unit: props.width_unit,
    height: props.height,
    height_unit: props.height_unit,
    clicks: props.clicks,
    clicks_trend: props.clicks_trend,
    views: props.views,
    views_trend: props.views_trend,
    ctr: props.ctr,
    ctr_trend: props.ctr_trend,
    favourite: props.favourite,
    hideHeader: props.hideHeader
  };

  const [placementProps, updatePlacementProps] = useState(
    placementDefaultProperties
  );

  const [tagData, setTagData] = useState([]);

  useEffect(() => {
    const loadTags = async () => {
      if (!statsRange.sid) return;
      const result = await axios.get(
        "https://api.norsktippingpartner.net/positions/tags" +
          "?session_key=" +
          statsRange.sid
      );

      let results = result.data.tags;
      setTagData(results);
    };
    loadTags();
  }, [statsRange.sid]);

  React.useEffect(() => {
    validateFields();
  }, [props.cat, validateFields]);

  React.useEffect(() => {
    validateFields();
  }, [placementProps, validateFields]);

  const [open, setOpen] = React.useState(false);
  const [snackMsg, setSnackMsg] = React.useState("Lenken er kopiert!");
  const [snackVar, setSnackVar] = React.useState("success");
  const [buttonsDisabled, setButtonsDisabled] = React.useState(
    props.buttonsDisabled
  );
  const isMobile = useMediaQuery("(max-width:767px)");
  const [anchorEl, setAnchorEl] = React.useState(null);

  const mobAlign = isMobile ? "left" : "right";

  const StyledMenu = withStyles({
    paper: {
      border: "1px solid #d3d4d5"
    }
  })(props => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
      {...props}
    />
  ));

  const StyledMenuItem = withStyles(theme => ({
    root: {
      "&:focus": {
        backgroundColor: theme.palette.background.secondary,
        "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
          color: theme.palette.text.primary
        }
      }
    }
  }))(MenuItem);

  function handleClickMeny(event) {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }
  function handleFavorites() {
    setAnchorEl(null);
    addRemoveFavorite();
  }
  function handleCloseMenu() {
    setAnchorEl(null);
  }
  function handleCloseDelete(event) {
    event.stopPropagation();

    props.handleDelete(props.id);
    setAnchorEl(null);
  }
  function updatePlacement() {
    if (placementProps.placement && placementProps.placement.length > 0) {
      setSnackVar("success");
      setSnackMsg("Oppdatert!");

      setOpen(true);
      props.updatePlacement(placementProps);
      props.updateBlockingStatus(false);
    } else {
      setOpen(true);

      setSnackVar("error");
      setSnackMsg("Skriv en tittel!");
    }
  }

  function setCopy() {
    setSnackMsg("Lenken er kopiert!");
    setOpen(true);
  }

  function validateFields() {
    if (
      placementProps.placement &&
      placementProps.placement.length > 0 &&
      placementProps.tag.length > 0 &&
      props.cat
    ) {
      setButtonsDisabled(false);
    } else {
      setButtonsDisabled(true);
    }
  }

  function handleClose(event) {
    setOpen(false);
  }
  const handleChange = name => event => {
    let newValue = event.target.value;
    //100% width is max
    if (
      name === "width" &&
      newValue > 100 &&
      placementProps.width_unit === "%"
    ) {
      newValue = 100;
      props.updateBlockingStatus(true);

      updatePlacementProps({ ...placementProps, [name]: newValue });
      props.updatePlacementPropsDimensions(name, newValue);
    } else if (
      name === "width_unit" &&
      newValue === "%" &&
      placementProps.width > 100
    ) {
      props.updateBlockingStatus(true);
      updatePlacementProps({ ...placementProps, [name]: newValue, width: 100 });

      props.updatePlacementPropsDimensionsMulti(name, newValue, "width", 100);
    } else {
      props.updateBlockingStatus(true);

      updatePlacementProps({ ...placementProps, [name]: newValue });
      props.updatePlacementPropsDimensions(name, newValue);
    }
  };

  function handleChangeTag(value) {
    let newValue = value.toLowerCase();
    props.updateBlockingStatus(true);

    updatePlacementProps({ ...placementProps, tag: newValue });
  }
  const handleFavorite = () => {
    let newValue = placementProps.favourite;

    updatePlacementProps({ ...placementProps, favourite: !newValue });
  };

  function addRemoveFavorite() {
    handleFavorite();
  }

  return (
    <>
      {!props.hideHeader && (
        <Paper className={classes.paper}>
          <Grid container spacing={0}>
            <Grid item xs={10} md={11}>
              <Typography
                variant="h1"
                component="h1"
                align="left"
                className={clsx(classes.heading, classes.placementHeading)}
              >
                {placementProps.favourite === false ? (
                  <StarIconOutlined
                    className={clsx(classes.hasCursor, classes.iconRightMarg)}
                    onClick={addRemoveFavorite}
                  />
                ) : (
                  <StarIcon
                    className={clsx(
                      classes.hasCursor,
                      classes.iconRightMarg,
                      classes.isFavorite
                    )}
                    onClick={addRemoveFavorite}
                  />
                )}{" "}
                {(!placementProps.placement ||
                  placementProps.placement === "") &&
                  "Ny plassering"}
                {placementProps.placement !== "" && placementProps.placement}
              </Typography>
            </Grid>
            <Grid item xs={2} md={1} className={classes.menuButtons}>
              <Schedule
                className={clsx(classes.hasCursor, classes.bigIcon)}
                onClick={props.handleOpenTimeschedule}
              />

              <IconButton
                disableRipple
                disableFocusRipple
                onClick={handleClickMeny}
                className={classes.buttonMenu}
              >
                <MoreVert className={classes.bigIcon} />
              </IconButton>
              <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
              >
                <StyledMenuItem onClick={handleCloseDelete}>
                  <ListItemIcon>
                    <DeleteIcon />
                  </ListItemIcon>
                  <ListItemText primary="Slett" />
                </StyledMenuItem>
                <StyledMenuItem onClick={handleFavorites}>
                  <ListItemIcon>
                    <StarIcon />
                  </ListItemIcon>
                  {!placementProps.favourite && (
                    <ListItemText primary="Legg til favoritt" />
                  )}
                  {placementProps.favourite && (
                    <ListItemText primary="Fjern fra favoritter" />
                  )}
                </StyledMenuItem>
              </StyledMenu>
            </Grid>
            <Grid item xs={12} md={6} className={classes.menuBar}>
              <MenuStats
                clicks={placementProps.clicks}
                clicks_trend={placementProps.clicks_trend}
                ctr={placementProps.ctr}
                ctr_trend={placementProps.ctr_trend}
                views={placementProps.views}
                views_trend={placementProps.views_trend}
              />
            </Grid>
            <Grid
              item
              container
              xs={12}
              spacing={0}
              className={classes.formElementHolder}
            >
              <Grid item xs={12} md={3} className={classes.mobPadFirst}>
                <TextField
                  id="filled-name"
                  label="Tittel"
                  value={placementProps.placement}
                  onChange={handleChange("placement")}
                  className={classes.textFieldInverse}
                  margin="normal"
                  variant="filled"
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={12} md={3} className={classes.mobPad}>
                {/*<TextField
                  id="filled-select-kategori"
                  select
                  label="Seksjon"
                  className={classes.textFieldInverse}
                  value={props.tag}
                  onChange={props.handleChange("tag")}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  helperText=""
                  margin="normal"
                  variant="filled"
                  disabled={buttonsDisabled}
                >
                  {categories.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
      */}
                <AutocompleteCreate
                  items={tagData}
                  handleChangeTag={handleChangeTag}
                  value={placementProps.tag}
                />
              </Grid>
              <Grid item xs={8} md={2} className={classes.mobPad}>
                <TextField
                  id="filled-width"
                  label="Bredde"
                  value={placementProps.width}
                  onChange={handleChange("width")}
                  className={classes.textFieldInverse}
                  margin="normal"
                  variant="filled"
                />
              </Grid>

              <Grid item xs={4} md={1} className={classes.mobPad}>
                <TextField
                  id="filled-select-unitwidth"
                  select
                  label="Enhet"
                  className={classes.textFieldInverse}
                  value={placementProps.width_unit}
                  onChange={handleChange("width_unit")}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  helperText=""
                  margin="normal"
                  variant="filled"
                >
                  {units.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={8} md={2} className={classes.mobPad}>
                <TextField
                  id="filled-height"
                  label="Høyde"
                  value={placementProps.height}
                  onChange={handleChange("height")}
                  className={classes.textFieldInverse}
                  margin="normal"
                  variant="filled"
                />
              </Grid>

              <Grid item xs={4} md={1} className={classes.mobPad}>
                <TextField
                  id="filled-select-unitheight"
                  select
                  label="Enhet"
                  className={classes.textFieldInverse}
                  value={placementProps.height_unit}
                  onChange={handleChange("height_unit")}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  helperText=""
                  margin="normal"
                  variant="filled"
                >
                  <MenuItem key={"hpx"} value={"px"}>
                    px
                  </MenuItem>
                </TextField>
              </Grid>
            </Grid>
            <Grid item xs={12} align={mobAlign}>
              <CopyToClipboard onCopy={setCopy} text={props.getPlacementURL()}>
                <Button
                  variant="outlined"
                  color="primary"
                  className={clsx(classes.buttonLeft, classes.mobbtn)}
                  disabled={buttonsDisabled}
                >
                  Kopier kode
                </Button>
              </CopyToClipboard>{" "}
              <Button
                variant="contained"
                color="primary"
                className={clsx(classes.button, classes.mobbtn)}
                disabled={buttonsDisabled}
                onClick={updatePlacement}
              >
                Oppdater
              </Button>
            </Grid>
          </Grid>
        </Paper>
      )}

      {props.hideHeader && (
        <Paper
          className={clsx(classes.paper, classes.pfixed)}
          style={{ width: props.divWidth }}
        >
          <Grid container spacing={0}>
            <Grid item xs={4} md={12}>
              <Typography
                variant="h1"
                component="h1"
                align="left"
                className={classes.heading}
              >
                {placementProps.placement}
              </Typography>
            </Grid>

            <Grid item xs={4} md={6} className={classes.mTop}>
              <MenuStats
                clicks={placementProps.clicks}
                clicks_trend={placementProps.clicks_trend}
                ctr={placementProps.ctr}
                ctr_trend={placementProps.ctr_trend}
                views={placementProps.views}
                views_trend={placementProps.views_trend}
              />
            </Grid>
            <Grid item xs={4} md={6} align="right">
              <CopyToClipboard onCopy={setCopy} text={props.getPlacementURL()}>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.buttonLeft}
                  disabled={buttonsDisabled}
                >
                  Kopier kode
                </Button>
              </CopyToClipboard>{" "}
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                disabled={buttonsDisabled}
                onClick={updatePlacement}
              >
                Oppdater
              </Button>
            </Grid>
          </Grid>
        </Paper>
      )}
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <MySnackbarContentWrapper
          onClose={handleClose}
          variant={snackVar}
          message={snackMsg}
        />
      </Snackbar>
    </>
  );
}
export default withRouter(PlacementProperties);
