import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";

import palette from "../../theme/palette";
import { withRouter } from "react-router-dom";
import Placements from "./Placements";
import useAxios from "axios-hooks";
import StatsContext from "../../context/StatsContext";
import update from "immutability-helper";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  toolbar: {
    paddingRight: 24 // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  menuButtonHidden: {
    display: "none"
  },
  title: {
    flexGrow: 1
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9)
    }
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto"
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  },
  button: {
    marginTop: theme.spacing(2)
  },
  fixedHeight: {
    height: 240
  },
  iconStyles: {
    verticalAlign: "middle",
    marginRight: theme.spacing(1)
  },
  heading: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  chip: {
    textTransform: "capitalize",
    margin: theme.spacing(1)
  },
  placementlist: {
    margin: "32px 0"
  },
  bigIcon: {
    verticalAlign: "middle",
    width: 24,
    height: 24,
    color: palette.text.secondary
  },
  outerPadding: {
    padding: "0 24px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 20px"
    }
  },
  rowMargin: {
    marginTop: "32px"
  },
  buttonTopMargin: {
    marginTop: "16px"
  },
  iconButton: {
    color: "rgba(0,0,0,0.54)"
  },
  circleProg: {
    marginTop: "48px"
  }
}));

function PlacementsPage(props) {
  const classes = useStyles();

  const [deleteURL, setDeleteURL] = useState("");
  const [addFavId, setAddFavId] = useState(null);
  const [removeFavId, setRemoveFavId] = useState(null);
  const [positionData, setPositionData] = useState(null);

  const statsRange = React.useContext(StatsContext);

  useEffect(() => {
    const loadPlacements = async () => {
      if (!statsRange.sid) return;
      const result = await axios.get(
        "https://api.norsktippingpartner.net/positions/" +
          "?session_key=" +
          statsRange.sid
      );

      let results = result.data.positions;
      setPositionData(results);
    };
    loadPlacements();
  }, [statsRange.sid]);

  useEffect(() => {
    if (!addFavId) return;
    const addNewFavorite = async () => {
      const result = await axios.get(
        "https://api.norsktippingpartner.net/position/favourite/" +
          addFavId +
          "?session_key=" +
          statsRange.sid
      );

      let results = result.data;
    };
    addNewFavorite();
  }, [addFavId, statsRange.sid]);

  useEffect(() => {
    if (!removeFavId) return;
    const removeNewFavorite = async () => {
      const result = await axios.get(
        "https://api.norsktippingpartner.net/position/unfavourite/" +
          removeFavId +
          "?session_key=" +
          statsRange.sid
      );

      let results = result.data;
    };
    removeNewFavorite();
  }, [removeFavId, statsRange.sid]);

  const [
    { dataDelete, loading: isLoadingDelete, error: isErrorDelete },
    deletePosition
  ] = useAxios(
    {
      url: deleteURL,
      method: "DELETE"
    },
    { manual: true }
  );

  function doDelete(itemId) {
    setDeleteURL(
      "https://api.norsktippingpartner.net/position/" +
        itemId +
        "?session_key=" +
        statsRange.sid
    );
  }

  React.useEffect(() => {
    const fetchData = async () => {
      if (deleteURL != "") {
        const result = await axios.delete(deleteURL);
      }
    };
    fetchData();
  }, [deleteURL]);

  function addToFavorites(pid, action) {
    if (action === false) {
      setAddFavId(pid);
    } else if (action === true) {
      setRemoveFavId(pid);
    }
    let updatedPositionKey;
    let updatedPosition = positionData.filter(function(item, key) {
      if (item.id === pid) {
        updatedPositionKey = key;
        return item;
      }
    });

    const updatedItem = update(positionData, {
      [updatedPositionKey]: { favourite: { $set: !action } }
    });

    setPositionData(updatedItem);
  }

  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      {!positionData && (
        <Grid item xs={12} className={classes.circleProg} align="center">
          <CircularProgress disableShrink />
        </Grid>
      )}
      {positionData && (
        <Placements
          items={positionData}
          doDelete={doDelete}
          addToFavorites={addToFavorites}
        />
      )}
    </main>
  );
}

export default withRouter(PlacementsPage);
