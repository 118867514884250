import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import PlacementIcon from "@material-ui/icons/FeaturedVideoOutlined";
import ContentIcon from "@material-ui/icons/TextFields";
import palette from "../../../theme/palette";
import Typography from "@material-ui/core/Typography";

import { withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  cardMedium: {
    display: "flex",
    marginLeft: "8px",
    marginRight: "8px",
    marginBottom: "16px",
    flexDirection: "column",
    "&:nth-child(3n-1)": {
      marginLeft: "calc(2% - 3px)",
      marginRight: "calc(2% - 3px)",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px",
      marginRight: "0px",
    },
  },
  mediaMedium: {
    height: 204,
  },

  cardList: {
    maxWidth: 1083,
  },
  cardListActions: {
    padding: 0,
  },
  cardListIconSmall: {
    marginRight: "5px",
    color: palette.text.secondary,
    width: 16,
    height: 16,
  },
  cardListIcon: {
    marginRight: "5px",
    color: palette.text.secondary,
    width: 22,
    height: 22,
  },
  absHeader: {
    position: "absolute",
    backgroundColor: "rgba(38, 20, 64, 0.8)",
    width: 716,
    zIndex: 100,
  },
  cardMenu: {
    display: "flex",

    justifyContent: "flex-end",
    alignItems: "center",
  },
  cardContent: {
    display: "flex",
  },
  circleNumber: {
    border: "1px solid " + palette.text.secondary,
    borderRadius: "50%",
    padding: "5px",
    margin: "0 16px",
    width: "24px",
    height: "24px",
    textAlign: "center",
    fontSize: "1.2rem",
    lineHeight: 1,
  },
  placementNr: {
    padding: "5px",
    margin: "0 16px",
    height: "24px",
    textAlign: "center",
    fontSize: "1.4rem",
    fontWeight: 600,
    lineHeight: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  flexHeading: {
    display: "flex",
    alignItems: "center",
    flex: "1",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  bigIconNoMarg: {
    width: 24,
    height: 24,
    color: palette.primary.main,
    marginRight: "4px",
  },
  mediumIcon: {
    width: 20,
    height: 20,
    marginRight: "16px",
  },
  largeIcon: {
    width: 22,
    height: 22,
    color: palette.greys.dark,
  },
  bigIcon: {
    width: 24,
    height: 24,
    color: palette.primary.main,
    marginRight: theme.spacing(2),
  },
  listIcon: {
    width: 22,
    height: 22,
    color: palette.greys.dark,
  },
}));

function ContentListItem(props) {
  const classes = useStyles();

  function handleClick() {
    if (props.match.path != "/newplacement" && props.isModal !== true) {
      props.history.push("/content/edit/" + props.id);
    } else {
      props.updateContent(props.row);
    }
  }
  //https://api.norsktippingpartner.net/thumbnail?url=https://api.norsktippingpartner.net/content/216/display
  function getImg() {
    let img =
      "https://api.miniature.io/?token=4W4aaB7nG7UusNru&url=api.norsktippingpartner.net/content/" +
      props.id +
      "/display" +
      "?ck=" +
      props.updated +
      "&width=380&height=380&delay=2";
    let cont_type = props.row.contenttype;
    if (
      cont_type === "betradarkampstatistikk" ||
      cont_type === "annonsebilag" ||
      cont_type === "spillmeny" ||
      cont_type === "htmlbanner" ||
      cont_type === "betradarkampliste" ||
      cont_type === "spillevett" ||
      cont_type === "tvkamper" ||
      cont_type === "oddstips-single" ||
      cont_type === "flax" ||
      cont_type === "dagenspott"
    ) {
      img = "/" + props.img;
    }
    img = "/" + props.img;
    return img;
  }
  //props.img
  //image={"/" + props.img}

  const placements = props.placements ? props.placements : 0;
  const posnames = props.posList ? props.posList.join(", ") : "";
  return (
    <Card className={classes.cardMedium} onClick={handleClick}>
      <CardActionArea>
        <CardMedia
          className={classes.mediaMedium}
          image={getImg()}
          title={props.heading}
        />
        <CardContent className={classes.cardContent}>
          <Typography
            variant="h4"
            component="h4"
            className={classes.flexHeading}
          >
            <ContentIcon className={classes.bigIcon} />
            {props.heading}
          </Typography>
          <div className={classes.cardMenu}>
            <div className={classes.placementNr} title={posnames}>
              <PlacementIcon className={classes.bigIconNoMarg} /> {placements}
            </div>
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default withRouter(ContentListItem);
