/* eslint-disable no-script-url */

import React, { useState, useEffect } from "react";

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CountUp from "react-countup";
import StatsContext from "../../../context/StatsContext";
import axios from "axios";

import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import palette from "../../../theme/palette";

const useStyles = makeStyles(theme => ({
  root: {
    margin: "40px 0 16px 0",
    backgroundColor: palette.secondary.light,
    width: "100%",
    padding: "30px 0",
    [theme.breakpoints.down("md")]: {
      margin: "0px 0 8px 0",
      padding: "10px 0"
    },
    borderCollapse: "inherit",
    position: "relative"
  },
  globalStats: {
    display: "flex",
    justifyContent: "center"
  },
  settings: {
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(2)
  },
  container: {
    color: "black",
    display: "flex"
  },
  heading: {
    width: "100%",
    display: "block",
    fontWeight: 400,
    fontSize: "1.2rem",
    color: palette.text.secondary,
    paddingLeft: "15px"
  },
  headingSettings: {
    width: "100%",
    display: "block",
    fontWeight: 400,
    fontSize: "1.4rem",
    color: palette.text.secondary,
    paddingLeft: "15px"
  },
  link: {
    textDecoration: "none"
  },
  body: {
    textAlign: "center",
    color: "black",
    fontSize: "20px",
    fontWeight: 600,
    padding: "0 20px",
    [theme.breakpoints.down("md")]: {
      padding: "0 10px"
    }
  },
  mediumIcon: {
    verticalAlign: "middle",
    width: 20,
    height: 20
  },
  smallIcon: {
    verticalAlign: "middle",
    width: 16,
    height: 16
  },
  settingsIcon: {
    marginLeft: theme.spacing(1),
    color: palette.text.secondary
  },
  upArrow: {
    color: palette.secondary.main
  },
  downArrow: {
    color: palette.error.main
  },
  downStats: {
    color: palette.error.main
  },
  upStats: {
    color: palette.secondary.main
  },
  cardListIconSmall: {
    margin: "0 4px",
    width: 20,
    height: 20
  }
}));

export default function TableGlobalStats(props) {
  const classes = useStyles();
  const statsRange = React.useContext(StatsContext);
  const statsInterval = statsRange.state;
  const [globalStatsData, setGlobalStats] = useState({
    ctr: 0,
    clicks: 0,
    views: 0,
    ctr_trend: "",
    views_trend: "",
    clicks_trend: ""
  });

  useEffect(() => {
    const loadStats = async () => {
      if (!statsRange.sid) return;
      const result = await axios.get(
        "https://api.norsktippingpartner.net/dashboard/stats/" +
          "?session_key=" +
          statsRange.sid
      );

      let results = result.data.stats[statsInterval];

      if (results) {
        setGlobalStats(results);
      }
    };
    loadStats();
  }, []);

  function renderArrow(trend) {
    if (trend === 1) {
      return (
        <ArrowUpward
          className={clsx(classes.upStats, classes.cardListIconSmall)}
        />
      );
    } else if (trend === -1) {
      return (
        <ArrowDownward
          className={clsx(classes.downStats, classes.cardListIconSmall)}
        />
      );
    } else {
      return (
        <span className={clsx(classes.downStats, classes.cardListIconSmall)}>
          {}
        </span>
      );
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.globalStats}>
        <div key={globalStatsData.views} className={classes.container}>
          <div className={classes.body}>
            <Typography
              variant="subtitle2"
              component="h5"
              align="center"
              className={classes.heading}
            >
              CTR
            </Typography>
            {renderArrow(globalStatsData.ctr_trend)}
            <CountUp start={0} end={globalStatsData.ctr} delay={0} decimals={3}>
              {({ countUpRef }) => <span ref={countUpRef} />}
            </CountUp>
          </div>

          <div className={classes.body}>
            <Typography
              variant="subtitle2"
              component="h5"
              align="center"
              className={classes.heading}
            >
              KLIKK
            </Typography>
            {renderArrow(globalStatsData.clicks_trend)}
            <CountUp start={0} end={globalStatsData.clicks} delay={0}>
              {({ countUpRef }) => <span ref={countUpRef} />}
            </CountUp>
          </div>

          <div className={classes.body}>
            <Typography
              variant="subtitle2"
              component="h5"
              align="center"
              className={classes.heading}
            >
              VIST
            </Typography>
            {renderArrow(globalStatsData.views_trend)}

            <CountUp start={0} end={globalStatsData.views} delay={0}>
              {({ countUpRef }) => <span ref={countUpRef} />}
            </CountUp>
          </div>
        </div>
      </div>
    </div>
  );
}
