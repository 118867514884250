import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { Link } from "react-router-dom";
import StatsContext from "../context/StatsContext";

import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import logo from "../images/logo.png";
import logomobil from "../images/logo.svg";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import ListItemText from "@material-ui/core/ListItemText";
import Hidden from "@material-ui/core/Hidden";

import Badge from "@material-ui/core/Badge";
import BarChartIcon from "@material-ui/icons/BarChart";

import HelpIcon from "@material-ui/icons/Help";
import NotificationsIcon from "@material-ui/icons/Notifications";
import ArrowBack from "@material-ui/icons/ArrowBack";
import MoreIcon from "@material-ui/icons/MoreVert";
import axios from "axios";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: "0 " + theme.spacing(3),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    color: "white",
    width: "250px",
  },
  logo: {
    margin: "auto",
    textAlign: "center",
  },
  logoHorizontallyCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 1,
  },
  sectionDesktop: {
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.up("md")]: {
      minWidth: "250px",
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  iconPadding: {
    marginRight: theme.spacing(1),
  },
  outerPadding: {
    padding: "0px",
    [theme.breakpoints.down("sm")]: {
      boxShadow: "none",
    },
  },
  statsTitle: {
    fontSize: "1.4rem",
  },
  arrowBack: {
    textDecoration: "none",
    color: "inherit",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  arrowPad: {
    marginRight: theme.spacing(3),
    width: "24px",
  },
}));

export default function ButtonAppBar(props) {
  const classes = useStyles();

  const statsRange = React.useContext(StatsContext);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [anchorElStats, setAnchorElStats] = React.useState(null);
  const [statsInterval, setStatsInterval] = React.useState(statsRange.state);

  const [saveStats, setSaveStats] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null);
  }

  function handleMenuClose() {
    setAnchorEl(null);
    handleMobileMenuClose();
  }

  function handleMobileMenuOpen(event) {
    setMobileMoreAnchorEl(event.currentTarget);
  }

  function handleStatsClick(event) {
    event.stopPropagation();
    setAnchorElStats(event.currentTarget);
  }

  function updateStatsInterval(val) {
    statsRange.updateValue(val);
    setAnchorElStats(null);
    setStatsInterval(val);
    saveStatsInterval(val);
    ///user/statspref/{hours}
  }

  useEffect(() => {
    if (!saveStats) return;
    const saveNewStatsInterval = async () => {
      const result = await axios.put(
        "https://api.norsktippingpartner.net/user/statspref/" +
          saveStats +
          "?session_key=" +
          statsRange.sid,
        {}
      );

      let results = result.data;
    };
    saveNewStatsInterval();
  }, [saveStats]);

  function saveStatsInterval(val) {
    setSaveStats(val);
  }

  const handleClose = () => {
    setAnchorElStats(null);
  };

  function getHeading() {
    if (props.path.indexOf("/placements/") !== -1) {
      return (
        <Link to="/placements" className={classes.arrowBack}>
          Plasseringer
        </Link>
      );
    } else if (props.path.indexOf("/placements") !== -1) {
      return "Plasseringer";
    } else if (props.path.indexOf("/collections/") !== -1) {
      return (
        <Link to="/collections" className={classes.arrowBack}>
          Samlesider
        </Link>
      );
    } else if (props.path.indexOf("/collections") !== -1) {
      return "Samlesider";
    } else if (props.path.indexOf("/content/") !== -1) {
      return (
        <Link to="/content" className={classes.arrowBack}>
          Innhold
        </Link>
      );
    } else if (props.path.indexOf("/content") !== -1) {
      return "Innhold";
    } else if (props.path.indexOf("/lenker") !== -1) {
      return "Lenker";
    } else if (props.path.indexOf("/hjelp") !== -1) {
      return "Hjelp";
    } else if (props.path.indexOf("/innstillinger") !== -1) {
      return "Innstillinger";
    } else if (props.path.indexOf("/newplacement") !== -1) {
      return (
        <Link to="/placements" className={classes.arrowBack}>
          Plasseringer
        </Link>
      );
    } else if (props.path.indexOf("/admin") !== -1) {
      return "Admin";
    } else {
      return "NT Partnerportal";
    }
  }

  function getArrow() {
    if (props.path.indexOf("/placements/edit") !== -1) {
      return (
        <Link
          to="/placements"
          className={clsx(classes.arrowBack, classes.arrowPad)}
        >
          <ArrowBack></ArrowBack>
        </Link>
      );
    } else if (props.path.indexOf("/content/") !== -1) {
      return (
        <Link
          to="/content"
          className={clsx(classes.arrowBack, classes.arrowPad)}
        >
          <ArrowBack></ArrowBack>
        </Link>
      );
    } else if (props.path.indexOf("/newplacement") !== -1) {
      return (
        <Link
          to="/placements"
          className={clsx(classes.arrowBack, classes.arrowPad)}
        >
          <ArrowBack></ArrowBack>
        </Link>
      );
    } else if (props.path.indexOf("/collections/") !== -1) {
      return (
        <Link
          to="/collections"
          className={clsx(classes.arrowBack, classes.arrowPad)}
        >
          <ArrowBack></ArrowBack>
        </Link>
      );
    } else {
      return (
        <div className={clsx(classes.arrowBack, classes.arrowPad)}>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={props.handleDrawerOpenClose}
          >
            <MenuIcon />
          </IconButton>
        </div>
      );
    }
  }

  const StyledMenu = withStyles({
    paper: {
      border: "1px solid #d3d4d5",
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      onClose={handleClose}
      value={statsRange.state}
      {...props}
    />
  ));

  const StyledMenuItem = withStyles((theme) => ({
    root: {
      "&:focus": {
        backgroundColor: theme.palette.background.secondary,
        "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
          color: theme.palette.text.primary,
        },
      },
    },
  }))(MenuItem);

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton aria-label="Show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="secondary">
            <HelpIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton aria-label="Show 11 new notifications" color="inherit">
          <Badge badgeContent={11} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
    </Menu>
  );

  const isMobile = useMediaQuery("(max-width:769px)");

  const NTlogo = isMobile ? logomobil : logo;
  const menuStatsText =
    "Viser siste  " +
    (statsRange.state == 1 ? " time " : statsRange.state + " timer ");

  const appLogin = (
    <AppBar position="fixed">
      <Toolbar>
        <div className={classes.logoHorizontallyCenter}>
          <img src={NTlogo} className={classes.logo} alt="logo" />
        </div>
      </Toolbar>
    </AppBar>
  );

  const appFull = (
    <AppBar position="fixed" className={classes.outerPadding}>
      <Toolbar>
        {getArrow()}
        <Hidden smDown>
          <Typography className={classes.title} variant="h6" noWrap>
            {getHeading()}
          </Typography>
        </Hidden>

        <div className={classes.logoHorizontallyCenter}>
          <img src={NTlogo} className={classes.logo} alt="logo" />
        </div>

        <div className={classes.sectionDesktop}>
          <IconButton
            aria-label="Statistikk"
            color="inherit"
            className={clsx(classes.iconPadding, classes.statsTitle)}
            onClick={handleStatsClick}
          >
            <Badge color="secondary">
              <BarChartIcon />
            </Badge>
            {!isMobile && menuStatsText}
          </IconButton>
          <StyledMenu
            id="customized-menu"
            anchorEl={anchorElStats}
            keepMounted
            open={Boolean(anchorElStats)}
          >
            <StyledMenuItem
              key={24}
              value={24}
              selected={statsRange.state === 24}
            >
              <ListItemText
                primary="Vis siste 24 timer"
                onClick={updateStatsInterval.bind(null, 24)}
              />
            </StyledMenuItem>
            <StyledMenuItem
              key={12}
              value={12}
              selected={statsRange.state === 12}
            >
              <ListItemText
                primary="Vis siste 12 timer"
                onClick={updateStatsInterval.bind(null, 12)}
              />
            </StyledMenuItem>
            <StyledMenuItem key={3} value={3} selected={statsRange.state === 3}>
              <ListItemText
                primary="Vis siste 3 timer"
                onClick={updateStatsInterval.bind(null, 3)}
              />
            </StyledMenuItem>
            <StyledMenuItem key={1} value={1} selected={statsRange.state === 1}>
              <ListItemText
                primary="Vis siste time"
                onClick={updateStatsInterval.bind(null, 1)}
              />
            </StyledMenuItem>
          </StyledMenu>

          <IconButton
            aria-label="Show 4 new mails"
            color="inherit"
            className={classes.iconPadding}
          >
            <Badge color="secondary">
              <Link to="/help" className={classes.arrowBack}>
                <HelpIcon />
              </Link>
            </Badge>
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
  );
  function getAppBar() {
    if (
      props.path == "/" ||
      props.path == "/forgot" ||
      props.path == "/logout" ||
      props.path.indexOf("/reset/") != -1
    ) {
      return appLogin;
    } else {
      return appFull;
    }
  }
  return (
    <div className={classes.root}>
      <ClickAwayListener onClickAway={props.handleMobileOpenClose}>
        {getAppBar()}
      </ClickAwayListener>
    </div>
  );
}
