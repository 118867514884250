import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import StatsContext from "../../../context/StatsContext";
import axios from "axios";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
//import { DropzoneArea } from "material-ui-dropzone";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";

const styles = (theme) => ({
  root: {
    margin: 0,
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
  },
  title: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  dialogWin: {
    maxWidth: "800px",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      margin: "0 !important",
    },
  },
  buttonMobile: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "0 0 8px 0 !important",
    },
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h1" className={classes.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

/*
'root'
  | 'scrollPaper'
  | 'scrollBody'
  | 'container'
  | 'paper'
  | 'paperScrollPaper'
  | 'paperScrollBody'
  | 'paperWidthFalse'
  | 'paperWidthXs'
  | 'paperWidthSm'
  | 'paperWidthMd'
  | 'paperWidthLg'
  | 'paperWidthXl'
  | 'paperFullWidth'
  | 'paperFullScreen';
*/
const DialogContainer = withStyles((theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      zIndex: "200 !important",
    },
  },
  paperFullWidth: {
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      width: "100%",
      padding: "0",
      height: "100%",
      maxHeight: "100%",
    },
  },
}))(Dialog);

const DialogContent = withStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(0),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(4),

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
}))(MuiDialogActions);

function OddsSettingDialog(props) {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    title: "",
    description: "",
    discus: "0",
    image: "",
  });

  const statsRange = React.useContext(StatsContext);
  const [fileObjects, setFileObjects] = React.useState([]);

  const getUploadParams = () => {
    return { url: "https://api.norsktippingpartner.net/image/upload/" };
  };

  const uploadImage = (img) => {
    const doUploadImage = async () => {
      const headers = {
        "Content-Type": "multipart/form-data",
      };

      var bodyFormData = new FormData();
      bodyFormData.append("image", img);
      const result = await axios.post(
        "https://api.norsktippingpartner.net/image/upload/" +
          "?session_key=" +
          statsRange.sid,
        bodyFormData,
        {
          headers: headers,
        }
      );

      let results = result.data;
      console.log(results);
    };
    doUploadImage();
  };
  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleChangeStatus = ({ meta, file, xhr }, status) => {
    if (status === "done") {
      let r = JSON.parse(xhr.response);
      setValues({ ...values, image: r.imageurl });
    } else if (status === "aborted") {
      console.log("aborted");
    }
  };

  function getSettingsInfo() {
    const loadSettings = async () => {
      const result = await axios.get(
        "https://api.norsktippingpartner.net/oddstips/info/" +
          "?session_key=" +
          statsRange.sid
      );

      let results = result.data;
      let infoObj = {
        title: results.oddsinfo.title,
        description: results.oddsinfo.description,
        discus: "0",
        image: results.oddsinfo.image,
      };
      console.log(results);
      setValues({ ...values, ...infoObj });
    };
    loadSettings();
  }

  function saveSettingInfo() {
    props.saveSettings(values);
  }

  React.useEffect(() => {
    if (props.isOpen) {
      getSettingsInfo();
    }
  }, [props.isOpen]);

  React.useEffect(() => {
    if (fileObjects.length > 0) {
      console.log(fileObjects);
      uploadImage(fileObjects[0].path);
    }
  }, [fileObjects]);
  return (
    <React.Fragment>
      <DialogContainer
        aria-labelledby="customized-dialog-title"
        open={props.isOpen}
        fullWidth={true}
        maxWidth="xl"
        className={classes.dialogWin}
      >
        <DialogTitle id="customized-dialog-title">
          Innstillinger for Oddstips
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={1} align="right">
            <Grid item xs={12} sm={12}>
              <TextField
                id="filled-name"
                label="Ditt navn"
                className={classes.textField}
                value={values.title}
                onChange={handleChange("title")}
                placeholder="Ditt navn"
                margin="normal"
                variant="filled"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              {/*<DropzoneArea
                dropzoneText={"Last opp bilde"}
                acceptedFiles={["image/*"]}
                filesLimit={1}
                getUploadParams={getUploadParams}
              />
              */}
              <Dropzone
                getUploadParams={getUploadParams}
                onChangeStatus={handleChangeStatus}
                maxFiles={1}
                multiple={false}
                canCancel={false}
                inputContent="Last opp bilde"
                styles={{
                  dropzone: { height: 200 },
                  dropzoneActive: { borderColor: "green" },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField
                id="filled-preamble"
                label="Beskrivelse"
                className={classes.textField}
                value={values.description}
                onChange={handleChange("description")}
                margin="normal"
                variant="filled"
                multiline
                rows="6"
                rowsMax="6"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={props.handleClose}
            variant="contained"
            color="secondary"
            className={classes.buttonMobile}
          >
            AVBRYT
          </Button>
          <Button
            onClick={saveSettingInfo}
            variant="contained"
            color="primary"
            className={classes.buttonMobile}
          >
            LAGRE
          </Button>
        </DialogActions>
      </DialogContainer>
    </React.Fragment>
  );
}

export default OddsSettingDialog;
